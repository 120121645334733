.contacts-valid {
  display: grid;
  grid-template-columns: 1fr 10.375rem;
  padding: 2.5rem;
  column-gap: 1rem;

  border-radius: 2.5rem;
  border: 1px solid $blue;

  &__content {
    display: grid;
    row-gap: 1.5rem; }

  &__title {
    @include h24;

    color: $blue;
    font-weight: 800; }

  &__desc {
    @include p18; }

  &__button-block {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;

    .btn {
      min-width: 0;
      width: 100%; } } }
