.modal-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1000; }

.modal {
  position: relative;
  display: block;
  width: 100%;
  max-width: 80vw;
  max-height: 85vh;
  margin: 0 1em;
  row-gap: 2.5em;
  padding: 1em;
  background: white;
  border-radius: 0.5em;

  @include media-tablet {
    min-width: 16.25rem;
    display: grid;
    padding: 2.5em; }

  @include media-tablet-h {
    max-width: 34em; }

  &-wrap {
    position: relative; }

  &__header {
    display: grid;
    row-gap: 1.5em; }

  &__title {
    @include reset;
    @include h36; }

  &__desc {
    @include fs14; }

  &__close {
    @include reset;
    position: absolute;
    top: 1em;
    right: 2.5em;
    width: 1.5em;
    height: 1.5em;
    transform: rotate(45deg);
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 2;

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      left: 50%;
      width: 1px;
      background-color: $dark-grey;
 }      // transform-origin: 50% 50%

    &::after {
      transform: rotate(90deg); } }

  &__rating-star-grid {
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    div {
      &:nth-child(even) {
        text-align: right;
        color: $red;
        font-weight: 800; } } }

  &--company-search {}

  &--netw-soon {
    row-gap: 1em;

    .modal__body {
      display: grid;
      row-gap: 1.5em; }

    .btn {
      justify-self: center; } }

  &--stand-users {
    width: 80vw;
    max-height: 100vh;
    max-width: 80vw;
    // overflow-y: auto

    .modal__grid {
      max-height: 60vh;
      overflow-y: scroll;
      padding-right: 1.5em;
      display: grid;
      row-gap: 2.5em;

      &::-webkit-scrollbar {
        width: 0.25em; }

      &::-webkit-scrollbar-track {
        background-color: $grey-f5; }

      &::-webkit-scrollbar-thumb {
        background-color: $grey-e5; }

      @include media-tablet-h {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.5em; }

      @include media-desktop-1440 {
        grid-template-columns: repeat(3, 1fr); } }

    .stand-user {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -1.25em;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $grey-e5;

        @include media-desktop-1440 {
          left: -1.25em;
          right: -1.25em; } }

      &::before {
        @include media-tablet-h {
          content: "";

          position: absolute;
          top: 0;
          bottom: 0;
          left: -1.25em;

          width: 1px;

          background-color: $grey-e5; } }

      &:nth-child(odd) {
        &::after {
          @include media-tablet-h {
            right: -1.25em; } }

        &::before {
          @include media-tablet-h {
            display: none; }

          @include media-desktop {
            display: block; } } }

      &:nth-child(even) {
        &::after {
          @include media-tablet-h {
            left: -1.25em; } } }

      &:nth-child(3n-2) {
        &::before {
          @include media-desktop-1440 {
            display: none; } } }

      &:nth-child(3n) {
        &::after {
          @include media-desktop-1440 {
            right: 0; } } }

      &__footer {
        @include media-tablet {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1em; } } }

    .btn {
      @include fs11;

      height: auto !important;
      padding: 0.6364em !important; } }

  &--stand-video {
    width: 70vw;
    height: 70vh;
    max-width: 70vw;
    max-height: 70vh;

    iframe {
      width: 100%;
      height: 100%; } }

  &--event-cancel {
    max-width: 60em;

    form {
      display: grid;
      row-gap: 2.5em; }

    .modal__body {
      display: grid;
      row-gap: 2.5em;

      textarea {
        height: 9.6429em; } }

    .modal__footer {
      display: flex;

      .btn {
        &:nth-child(n+2) {
          margin-left: 1em; } } } }

  &--has-no-profile {
    .modal__body {
      a {
        color: $red;
        text-decoration: underline; } } }
  &--end-event {
    .modal__header {
      padding-top: 2em;
      margin-bottom: 2em;
      @include media-tablet {
        padding-top: 1em;
        margin-bottom: 0; } }

    .modal__body {
      margin-bottom: 1em;
      @include media-tablet {
        margin-bottom: 0; }
      a {
        color: $red;
        text-decoration: underline; } } }

  &__event {
    display: grid;
    row-gap: 1.5em;
    padding-right: 1.5em;

    &-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &--first {} }

    &-date {
      @include fs18;

      margin-right: 1.1111em; }

    &-time {
      @include fs18;

      font-weight: 800;
      margin-right: 1.3333em; }

    &-type {
      @include fs11;

      display: flex;
      align-items: center;
      padding: 0.3636em 0.6364em;

      border: 1px solid $grey;
      border-radius: 0.3636em;

      img {
        margin-left: 1em; }

      &--meet-request {
        border-color: $lightblue;
        color: $lightblue; }

      &--broadcast {
        border-color: $yellow-calendar;
        color: $yellow-calendar; }

      &--approved {
        border-color: $green-calendar;
        color: $green-calendar; }

      &--waiting {
        border-color: $grey-ee; } }

    &-name {
      @include p14; }

    &-btn {
      font-weight: 400 !important;

      &.btn--red {
        font-weight: 700 !important; }

      &--disabled {
        opacity: 0.4; } } }

  &--new-event-user {
    &::-webkit-scrollbar {
      width: 0.25em; }

    &::-webkit-scrollbar-track {
      background-color: $grey-f5; }

    &::-webkit-scrollbar-thumb {
      background-color: $grey-e5; }

    &>hr {
      height: 1px;
      width: 100%;
      margin: 0;

      background-color: $grey-c0;
      border: none; }

    .modal {
      &__grid {
        max-height: 40vh; }

      &__body {
        display: grid;
        row-gap: 1.5em;

        &-title {
          @include reset;
          @include h24; } } }

    .btn {
      svg {
        margin-left: 0.4em; }

      &--white-grey {

        svg,
        path {
          fill: $dark-grey; } } } }

  &--content {
    @include fs18;

    max-width: 85vw;
    max-height: 90vh;
    overflow-y: auto;
    word-break: break-word;
    @include media-tablet {
      word-break: initial; }
    @include media-desktop {
      max-width: 60rem; }

    p {
      margin: 1rem 0; }

    hr {
      margin: 2.5rem 0 4rem;
      height: 1px;

      background-color: $grey-c0;
      border: none; }

    .modal__title {
      @include reset;
      @include h18;
      padding-right: 2em;
      @include media-tablet {
        padding-right: 0;
        @include h36; } }

    h2 {
      @include h24;

      margin: 4rem 0 1rem; }

    h3 {
      @include fs18;

      margin: 2.5rem 0 1rem;

      font-weight: 800; }

    ul,ol {
      padding-left: 2.5rem;

      li {
        margin: 1rem 0; } } }

  &--use-no-auth {
    min-width: 20em;
    margin: 0;
    padding: 2.5rem 1.25rem;
    border-radius: 1.25em;
    @include fs16;

    @include media-tablet {
      max-width: 40rem;
      padding: 2.5rem; }

    @include media-tablet-h {
      display: flex;
      column-gap: 3.625rem;
      max-width: 60rem; }

    .col-left {
      .modal__title {
        margin-bottom: 1.25rem;
        padding-right: 0;
        @include fs24;

        @include media-tablet {
          @include h36; }

        @include media-tablet-h {
          margin-bottom: 2.5rem; } }

      .modal__body {
        display: flex;
        flex-direction: column;
        row-gap: 2.5rem; }

      .modal__desc {
        @include media-tablet {
          @include fs18; }

        @include media-tablet-h {
          display: none; } }

      .steps-netw {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        @include reset;

        &__item {
          @include reset;
          @include fs14;
          list-style: none;

          span {
            font-weight: 800; }

          a {
            color: $blue; }

          @include media-tablet {
            @include fs18; }

          @include media-tablet-h {
            @include fs16; } } }

      .btn--medium {
        min-width: auto;
        height: 3.056em;
        color: $white; } }

    .col-right {
      position: relative;
      display: none;
      flex: 0 0 24.1875rem;

      .modal__desc {
        @include media-tablet-h {
          padding-top: 2.375em;
          padding-left: 3.0625em;
          @include fs16; } }

      img {
        position: absolute;
        right: -2.5em;
        bottom: -0.9375em;
        min-width: 26.6875em; }

      @include media-tablet-h {
        display: block; } } }

  &--use-with-auth {
    max-width: 20em;
    padding: 0;
    border-radius: 1.25em;
    @include fs16;

    @include media-tablet {
      display: block;
      max-width: 40em; }

    @include media-desktop {
      max-width: 60em; }

    .modal__header {
      padding: 1.3125em 1.25em 0;
      margin-bottom: 0.8125em;

      @include media-tablet {
        padding: 1.5625em 2.5em 0; } }

    .modal__title {
      padding: 0;
      color: $blue;
      @include fs14;
      font-weight: normal; } }

  &--in-touch {
    display: flex;
    align-items: flex-end;
    column-gap: 6.25rem;
    min-width: 20em;
    padding: 2.5em 1.25em;

    @include media-tablet {
      padding: 2.5em; }

    @include media-tablet-h {
      max-width: 60em;
      padding: 2.5em 2.5em 3.5625em; }

    .modal__close {
      right: 1.5em; }

    .col-left {
      @include media-tablet-h {
        flex: 0 0 27.5em; } }

    .modal__title {
      margin-bottom: 1.25rem;
      @include fs24;

      @include media-tablet {
        @include h36; } }

    .modal__body {
      display: flex;
      flex-direction: column; }

    .modal__desc {
      margin-bottom: 2.5rem;

      @include media-tablet {
        @include fs18; } }

    .btn--large {
      padding-right: 2em;
      padding-left: 2em;
      color: $white; }

    .col-right {
      display: none;

      @include media-tablet-h {
        display: block; } }

    .logo-telegram {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 210px;
      border-radius: 20px;
      background-color: $grey-f0;

      img {
        width: 140px;
        height: 140px; }

      &::after {
        content: '3';
        position: absolute;
        top: -1.375rem;
        right: -1.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        background-color: $grey-88;
        color: $white;
        font-size: 1.5em; } } }

  &--new-webinars {
    min-width: 20em;
    padding: 2.5em 1.25em;
    overflow: hidden;

    @include media-tablet {
      padding: 2.5em; }

    @include media-tablet-h {
      max-width: 75em; }

    @include media-desktop {
      width: 75em;
      padding: 3.75em; }

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;
      background: url(@public/images/modals/figure-1.svg) no-repeat;

      @include media-desktop {
        display: block; } }

    &::before {
      width: 24.25em;
      height: 16.375em; }

    &::after {
      width: 18.6875em;
      height: 19.125em;
      background-image: url(@public/images/modals/figure-2.svg); }

    .modal__body {
      max-width: 45.75em; }

    .modal__title {
      @include fs24;
      margin-bottom: 1.5rem;

      @include media-tablet {
        @include h36;
        margin-bottom: 2.5rem; }

      @include media-tablet-h {
        font-size: 2.5em; } }

    .modal__desc {
      @include fs18;
      margin-bottom: 2.5rem;

      @include media-tablet {
        @include fs22;
        margin-bottom: 3.75rem; }

      @include media-tablet-h {
        font-size: 1.75em; } }

    .btn {
      width: 100%;
      max-width: 32.25rem;
      padding: 0; }

    .modal__close {
      right: 1em;

      @include media-tablet {
        top: 1.5625em;
        right: 1.5625em; } } } }
