.userbar {
  position: sticky;
  top: 3.625em;

  display: flex;
  flex-direction: column;
  padding: 2.5em 1.25em 2.5em 2.5em;

  color: $dark-grey;

  hr {
    height: 0.0625em;
    margin: 1.25em 0;

    border: none;
    background-color: $grey-c0; }

  &__avatar {
    margin-bottom: 1em;
    overflow: hidden;
    display: flex;
    position: relative;
    width: min-content;
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7.5em;
      height: 7.5em;
      border-radius: 50%;
      background: #C4C4C4;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        overflow: hidden; } }
    &-imgDefault {
      width: 40px !important;
      height: auto !important; }
    &-changer {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #E5E5E5;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      &:hover {
        opacity: 0.8; }
      input {
        position: absolute;
        width: 38px;
        height: 38px;
        opacity: 0; }
      img {
        width: 16px; } } }
  &__name {
    @include fs18;

    font-weight: 800; }

  &__position {
    @include fs11;

    margin-top: 0.3636em;

    color: $grey-88; }

  &__company {
    display: flex;
    flex-direction: column;

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &--notAdded {
        .userbar__company {
          &-img {
            border: 1px dashed #C0C0C0;
            border-radius: 0; }

          &-add {
            @extend .main-nav__dropdown-company-btn;

            margin-top: 0.5em;

            text-align: center; } } } }

    &-title {
      @include fs14;

      color: $grey-c0; }

    &-img {
      width: 5.625em;
      height: 3.75em;
      padding: 0.4687em;
      margin-top: 1em;

      border: 0.0625em solid $grey-ee;
      border-radius: 0.25em;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center; } }

    &-name {
      @include fs14;

      margin-top: 0.8571em;

      color: $dark-grey;
      font-weight: 800;
      text-decoration: underline;

      &:hover {
        text-decoration: none; } } }

  &__time {
    @include fs14;

    color: $grey-c0; }

  &__support {
    display: grid;
    row-gap: 0.25em;

    color: $grey-c0;

    p {
      @include reset; }

    &>* {
      @include p14; }

    a {
      color: $red; } } }
