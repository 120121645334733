.br-10 {
  border-radius: 10px; }
.br-4 {
  border-radius: 4px; }

.b-red {
  border: 2px solid $color-red; }
.br-circle {
  border-radius: 100px; }

.br-top-no {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.b-top-no {
  border-top: none !important; }

.b-left-no {
  border-left: none !important; }

.b-right-no {
  border-right: none !important; }

.b-bottom-no {
  border-bottom: none !important; }

.b-no {
  border: none !important; }

.br-no {
  border-radius: 0; }

.br-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.br-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

.bd-1 {
  border: 1px solid rgba(170, 181, 208, 0.1); }

.b-right-1 {
  border-right: 1px solid rgba(170, 181, 208, 0.1); }

.b-bottom-1 {
  border-bottom: 1px solid rgba(170, 181, 208, 0.1); }

