.stand-guide {
  position: relative;

  display: block;
  padding-top: 19em;

  color: $color-black;

  overflow: hidden;

  @include media-tablet {
    padding-top: 1.5em;
    padding-left: 23em; }

  @include media-tablet-h {
    padding-left: 24.625em; }

  @include media-desktop {
    padding-top: 2.5em;
    padding-left: 25.625em; }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 17.5em;

    @include media-tablet {
      right: auto;
      bottom: 0;

      width: 21.5em;
      height: auto; }

    @include media-tablet-h {
      width: 23.125em; }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center; } }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: start; }

  &__tag {
    @include fs14;

    width: auto;
    padding: 0.3571em 0.6429em;

    border-radius: 2em;
    background-color: $grey-f0; }

  &__title {
    @include fs18;

    margin-top: 0.8889em;

    font-weight: 800;

    @include media-tablet {
      @include h24;

      margin-top: 0.6667em; } }

  &__desc {
    @include fs14;

    margin-top: 0.8571em;

    @include media-tablet {
      @include fs18;

      margin-top: 0.6667em; } } }
