@keyframes ping-pong {
  50% {
    transform: translateY(50%); }

  100% {
    transform: translateY(0); } }

@keyframes header {
  0% {
    opacity: 0; }

  16.66% {
    opacity: 1; }

  27.77% {
    opacity: 0; } }

@keyframes preloader {
  10% {
    transform: scale(2);
    background: $red;
    opacity: 1; } }
