.company-bar {
  position: sticky;
  top: 3.625em;

  display: grid;
  row-gap: 1.25em;
  padding: 2.5em 1.25em 2.5em 2.5em;

  color: $dark-grey;

  &>hr {
    height: 0.0625em;
    margin: 0;

    border: none;
    background-color: $grey-c0; }

  &__header {
    display: grid;
    row-gap: 1em; }

  &__img {
    width: 9em;
    height: 6em;
    padding: 0.75em;

    border: 0.0625em solid $grey-e5;
    border-radius: 0.25em;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center; } }

  &__title {
    display: grid;
    row-gap: 0.25em; }

  &__company {
    &-name {
      @include fs14;

      font-weight: 800; }

    &-desc {
      @include fs11;

      color: $grey-88; } }

  &__nav {
    &-list {
      @extend .usernav__list; }

    &-item {
      &--active {}

      &-link {
        @extend .usernav__item-link; }

      &-counter {
        @extend .usernav__item-counter;

        &--active {
          @extend .usernav__item-counter--active; } } } }

  &__time {
    @extend .userbar__time; }

  &__company-link {
    @include fs11;

    color: $blue;

    text-decoration: underline;

    &:hover {
      text-decoration: none; } } }
