.stand-schedule {
  display: grid;
  row-gap: 2.5em;

  &__title {
    @include h36; }

  &__list {
    display: grid;
    row-gap: 2.5em; }

  &__item {
    display: grid;
    row-gap: 1.5em;
    padding-bottom: 2.5em;

    border-bottom: 0.0625em solid #dcdcdc;

    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }

    @include media-tablet {
      grid-template-columns: 1fr 12.875em;
      column-gap: 1.5em; }

    &-content {
      display: grid;
      grid-row-gap: 0.5em;

      @include media-tablet {
        grid-template-columns: auto 1fr;
        column-gap: 4.75em; } }

    &-date {
      @include fs18;

      display: flex;

      @include media-tablet {
        @include h24;

        font-weight: 400; } }

    &-time {
      position: relative;

      margin-left: 0.6667em;
      padding-left: 0.7778em;

      font-weight: 800;

      &::before {
        content: "";

        position: absolute;
        left: 0;
        top: 4px;
        bottom: 4px;

        width: 2px;
        background-color: $dark-grey; } }

    &-timezone {
      position: absolute;
      right: -0.3636em;
      top: 0;
      transform: translateX(100%);

      font-size: 0.6111em; }

    &-day {
      @include fs14;

      color: $grey-82;
      text-transform: capitalize;

      @include media-tablet {
        align-self: center; } }

    &-title {
      @include fs14;

      padding-top: 0.2857em;

      @include media-tablet {
        @include p16;

        grid-column: 1 / span 2; } }

    &-btn-wrapper {
      display: grid;
      row-gap: 1em;

      @include media-tablet {
        grid-template-rows: auto 1fr; }
      .btn {
        height: 3em;
        font-weight: bold !important; } }

    &-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5em; }

    &-views,
    &-participants {
      @include fs11;

      color: $grey-82; } }

  &__more-link {
    @include p14;

    padding: 0.5714em 0;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $dark-grey;

    border-radius: 0.2857em;
    background-color: $grey-f5;

    img {
      margin-left: 1em; } } }
