.partners {
  padding: 2.5em 0;
  background-color: $grey-f0;

  @include media-tablet {
    padding: 2em 0; }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.25em;
    padding: 1.875em 1.25em;
    border-radius: 1.5em;
    background: $white;

    @include media-tablet {
      padding: 1.875em; }

    @include media-tablet-h {
      row-gap: 2.5em;
      padding: 1.875em 2.5em; }

    @include media-desktop {
      display: grid;
      grid-template-columns: max-content 1px auto;
      column-gap: 3.75em; }

    @include media-desktop-1440 {
      padding: 3.125em 4em; } }

  hr {
    width: 100%;
    height: 1px;
    margin: 0;
    background: $grey-89;
    border: none;
    opacity: 0.2;

    @include media-desktop {
      width: 1px;
      height: 100%; } }

  &__group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    row-gap: 1.25em;

    @include media-tablet {
      flex-direction: row;
      align-items: center;
      column-gap: 1.875em; }

    @include media-tablet-h {
      column-gap: 1.875em; }

    @include media-desktop {
      column-gap: 2.5em; }

    &-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1.25em;

      @include media-tablet {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        gap: 2.5em 4em; }

      @include media-desktop {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 3.75em; } }

    &--one-item {
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }

    &--org {
      @include media-tablet {
        column-gap: 1.125em; }

      @include media-tablet-h {
        column-gap: 1.875em; }

      @include media-desktop {
        column-gap: 2.5em; }

      .partners__title {
        @include media-tablet {
          width: min-content; } }

      .partners__item {
        height: 3.375em;

        @include media-tablet {
          height: 2.8125em; }

        @include media-tablet-h {
          height: 3.375em; }

        @include media-desktop {
          height: 4.3125em; } } }

    &--general {
      @include media-tablet {
        column-gap: 1.125em; }

      @include media-tablet-h {
        column-gap: 1.875em; }

      @include media-desktop {
        column-gap: 2.5em; }

      .partners__title {
        @include media-tablet {
          width: min-content; } }

      .partners__item {
        height: 2.8125em;

        @include media-tablet {
          height: 2.1875em; }

        @include media-tablet-h {
          height: 2.8125em; }

        @include media-desktop {
          height: 3.4375em; } } }

    &--strategic {
      @include media-tablet {
        column-gap: 1.125em; }

      @include media-tablet-h {
        column-gap: 1.875em; }

      @include media-desktop {
        column-gap: 2.5em; }

      .partners__title {
        @include media-tablet {
          width: min-content; } }

      .partners__item {
        height: 2.8125em;

        @include media-tablet {
          height: 2.1875em; }

        @include media-tablet-h {
          height: 2.8125em; }

        @include media-desktop {
          height: 3.4375em; } } }

    &--support {
      @include media-tablet {
        display: grid;
        grid-template-columns: 7.8125rem 1fr;
        width: 100%; }

      @include media-desktop {
        grid-template-columns: 9.5rem 1fr;
        align-items: flex-start; }

      @include media-desktop-1600 {
        grid-template-columns: 10.625rem 1fr; }

      & + hr {
        @include media-tablet {
          display: none; } }

      .partners__title {
        @include media-desktop {
          display: flex;
          align-items: center;
          height: 4.125rem; }

        @include media-desktop-1600 {
          height: 5rem; } }

      .partners__list {
        @include media-tablet {
          gap: 1.25em 1.875em; }

        @include media-desktop {
          gap: 1.875em; }

        @include media-desktop-1600 {
          gap: 1.875em 3.125em; } }

      .partners__item {
        @include media-tablet {
          max-width: 6.5em;
          height: 3.375em; }

        @include media-tablet-h {
          height: 4.125em; }

        @include media-desktop-1600 {
          max-width: 8.125em;
          height: 5em; } } }

    &--other {
      @include media-tablet {
        display: grid;
        grid-template-columns: 7.8125rem 1fr;
        width: 100%; }

      @include media-desktop {
        grid-template-columns: 9.5rem 1fr;
        align-items: flex-start; }

      @include media-desktop-1600 {
        grid-template-columns: 10.625rem 1fr; }

      .partners__title {
        @include media-desktop {
          display: flex;
          align-items: center;
          height: 2.8125rem; }

        @include media-desktop-1600 {
          height: 3.4375rem; } } } }

  &__title {
    @include fs12;
    width: 5.625rem;
    color: $grey-889;
    opacity: 0.7;

    @include media-tablet-h {
      @include fs14;
      width: auto; }

    @include media-desktop {
      @include fs20;
      width: 9.5rem; } }

  &__list {
    @include lreset;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25em 1.875em;

    @include media-tablet {
      gap: 1.25em; }

    @include media-tablet-h {
      gap: 1.25em 2em; }

    @include media-desktop {
      gap: 1.875em; } }

  &__item {
    width: auto;
    max-width: 7.5em;
    height: 3.375em;

    @include media-tablet {
      width: auto;
      max-width: 10em;
      height: 2.125em; }

    @include media-tablet-h {
      height: 2.8125em; }

    @include media-desktop-1600 {
      height: 3.4375em; }

    img {
      @include contain-img; } } }
