.input {
  &[type="text"],
  &[type="email"],
  &[type="password"],
  &[type="number"],
  &[type="phone"] {
    @include p14;

    height: 2.375rem;
    width: 100%;
    padding: 0 0.8571em;

    border-radius: 2rem;
    border: 1px solid $grey-e5;
    appearance: none;

    &::placeholder {
      font-family: $base-font;

      color: $grey-88; } } }
