.about-netw {
  background: $color-black;

  @include media-tablet-h {
    height: 46.25em; }

  @include media-desktop {
    height: 50em; }

  @include media-desktop-1920 {
    height: 46.25em; }

  &__main {
    @include media-tablet-h {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 2.5em;
      height: 100%; }

    @include media-desktop {
      column-gap: 3.75em; }

    @include media-desktop-1600 {
      position: relative; } }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    color: $white;

    @include media-tablet {
      align-items: flex-start;
      row-gap: 3em;
      padding-top: 3.75em;
      padding-bottom: 3.75em; }

    @include media-tablet-h {
      row-gap: 3.75em;
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0; }

    @include media-desktop {
      row-gap: 5em; }

    @include media-desktop-1600 {
      margin-left: auto; } }

  &__title {
    @include h24;

    @include media-tablet {
      @include h48;
      width: 34.6875rem; }

    @include media-desktop-1440 {
      width: 100%; }

    @include media-desktop-1600 {
      width: 46.5rem; }

    br {
      @include media-tablet {
        display: none; }

      @include media-tablet-h {
        display: block; } }

    span {
      text-transform: uppercase; } }

  &__stats {
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    @include media-tablet {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      column-gap: 1.25em; }

    @include media-desktop-1440 {
      column-gap: 2.5em; }

    &-item {
      display: flex;
      flex-direction: column;

      @include media-tablet {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 0; }

      &:not(:last-child) {
        padding-bottom: 1em;
        border-bottom: 1px solid #54556b;

        @include media-tablet {
          padding-right: 1.25em;
          padding-bottom: 0;
          border-right: 1px solid #54556b;
          border-bottom: none; }

        @include media-tablet-h {
          padding-right: 1.25em; }

        @include media-desktop-1440 {
          padding-right: 2.5em; } }

      &:last-child {
        .about-netw__stats-text {
          @include media-desktop-1440 {
            width: 15.5rem; } } } }

    &-number {
      @include h36;
      display: flex;
      align-items: center;
      column-gap: 0.75rem;

      @include media-desktop-1440 {
        @include h48; }

      span {
        padding: 0 0.3125rem;
        font-size: 0.875rem;
        line-height: 1.3;
        font-weight: 800;
        color: #54556b;
        border: 1px solid #54556b;
        border-radius: 2.4375rem;

        @include media-tablet {
          margin-top: -1em;
          padding: 0 0.40625rem;
          font-size: 1rem; } } }

    &-text {
      @include fs14;

      @include media-tablet {
        max-width: 12.125rem; } }

    & + hr {
      display: none;
      width: 100%;
      height: 1px;
      margin: 0;
      border: 0;
      background: $white;
      opacity: 0.33;

      @include media-tablet-h {
        display: block; }

      @include media-desktop-1600 {
        width: 48em; } } }

  &__bottom {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;

    @include media-tablet {
      width: 100%; }

    &-text {
      @include h36;
      display: none;

      @include media-tablet-h {
        display: block; } } }

  &__btn {
    font-weight: 700;

    @include media-tablet {
      width: 100%; }

    @include media-tablet-h {
      @include h24;
      width: 16.1875rem;
      min-width: auto;
      height: 3.875rem; } } }

.forum-news {
  max-height: 37.8125em;
  padding: 1.25em 0.625em 1.25em 1.25em;
  color: $color-black;
  background: $white;
  overflow: hidden;

  @include media-tablet {
    max-height: 31.4375em;
    padding: 2em 1.875em 1.875em; }

  @include media-tablet-h {
    min-width: 20.9375em;
    height: 100%;
    max-height: none;
    padding: 1.5em 1.875em 1.5em 1.5em;
    border-radius: 1.5em 0 0 1.5em; }

  @include media-desktop {
    min-width: 33.75em;
    padding: 2.5em; }

  @include media-desktop-1600 {
    position: absolute;
    top: 0;
    right: 0;
    width: 43.75em; }

  @include media-desktop-1920 {
    width: 40%; }

  &__title {
    @include h24;
    margin-bottom: 1.25rem;

    @include media-tablet {
      margin-bottom: 1.5rem; } }

  &__list {
    max-height: 33.4375em;
    padding-right: 0.625em;
    // overflow-y: scroll
    overflow-y: auto;
    scrollbar-color: rgba($dark-grey, 0.15) rgba($dark-grey, 0.05);
    scrollbar-width: thin;

    @include media-tablet {
      max-height: 25.6875em;
      padding-right: 1.875em; }

    @include media-tablet-h {
      height: 41.375em;
      max-height: none;
      padding-right: 3em; }

    @include media-desktop {
      height: 44.125em;
      padding-right: 4em; }

    @include media-desktop-1600 {
      padding-right: 15.1875em; }

    @include media-desktop-1920 {
      height: 40.375em;
      padding-right: 40%; }

    &::-webkit-scrollbar {
      width: 0.25rem;
      margin-right: 5px; }

    &::-webkit-scrollbar-track {
      background-color: rgba($dark-grey, 0.05); }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($dark-grey, 0.15);
      border-radius: 1.25rem; } }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
    margin-bottom: 1.25em;
    padding-bottom: 1.25em;

    @include media-tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }

    &:not(:last-child) {
      border-bottom: 1px solid #d9d9de; }

    &-title {
      @include fs14;
      font-weight: 800; }

    &-date-block {
      display: flex;
      align-items: center;
      column-gap: 0.75em;

      hr {
        height: 0.875em;
        width: 1px;
        margin: 0;
        background: $color-black;
        border: none; } }

    &-time {
      position: relative;
      @include fs14;

      span {
        position: relative;
        top: -0.4em;
        font-size: 0.6875em;
        line-height: 1.35; } }

    &-date {
      @include fs14; }

    &-text {
      @include fs14;

      @include media-tablet {
        flex-basis: 100%; } } } }
