.input-phone {
  display: grid;
  grid-template-columns: auto 1fr;

  select {
    @include p14;

    height: 2.7143em;
    width: 100%;
    padding: 0 0.8571em;

    background-color: transparent;
    border-radius: 0.2857em;
    border: 1px solid $grey-e5;
    appearance: none;
    user-select: none;

    flex-grow: 0;
    appearance: none;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::placeholder {
      font-family: $base-font;

      color: $grey-88; }

    &.error {
      outline: none;
      box-shadow: none;

      border-color: $red; } }

  input {
    @extend .input[type="text"];

    flex-grow: 1;

    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; } }
