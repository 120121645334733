.event-create-step-2 {
  display: grid;
  row-gap: 1.5em;

  @include media-tablet {
    grid-template-areas: "title timezone" "calendar time" "final final";
    grid-template-rows: auto auto 1fr;
    grid-template-columns: auto 1fr;
    column-gap: 2em; }

  @include media-tablet-h {
    grid-template-columns: auto 1fr 1fr;
    grid-template-areas: "title timezone timezone" "calendar time final";
    grid-template-rows: auto 1fr; }

  &__content {
    @include media-tablet {
      grid-area: title; } }

  &__calendar {
    @include media-tablet {
      grid-area: calendar; } }

  &__title {
    @extend .event-create-step-1__title; }

  &__desc {
    @extend .event-create-step-1__desc; }

  &__timezone {
    position: relative;
    display: flex;

    @include media-tablet {
      grid-area: timezone;
      align-self: center;
      user-select: none;
      justify-content: flex-end; }

    &-wrapper {
      position: relative;
      width: 100%;

      @include media-tablet {
        width: auto; } }

    &-trigger,
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        &:first-of-type {
          @include fs14;
          text-transform: uppercase; }

        &:last-of-type {
          @include fs18;
          display: flex;
          align-items: center;
          font-weight: 800; }

        img {
          margin-left: 0.5em;
          transform: rotate(-90deg);
          transform-origin: 50% 50%; } } }

    &-trigger {
      @include fs18;
      justify-content: space-between;
      align-items: flex-end;
      column-gap: 0.625rem;
      cursor: pointer;

      @include media-tablet {
        justify-content: flex-end; }

      span {
        &:last-of-type {
          @include media-tablet {
            font-size: 1em; } } } }

    &-list {
      position: absolute;
      top: 2em;
      right: 0;
      left: 0;
      z-index: 999;
      height: 0;
      background-color: $grey-f5;
      box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
      border-radius: 0.25em;
      overflow: hidden;

      &--open {
        height: auto; }

      label {
        padding: 0.75em 1.5em;
        cursor: pointer;

        @include media-tablet {
          padding: 0.75em 1em; } } } }

  &__time {
    display: grid;
    row-gap: 1.5em;
    padding-bottom: 1em;
    overflow-y: scroll;

    @include media-tablet {
      grid-area: time;
      align-self: stretch; }

    &-list {
      display: grid;
      row-gap: 0.75em;
      height: auto;
      max-height: 24.1875em;
      padding-right: 1em;

      @include media-tablet {
        max-height: 0; } }

    &-item {
      position: relative;

      label {
        display: block;
        padding: 0.75em;
        text-align: center;
        border: 0.0625em solid $grey-c0;
        border-radius: 2.0625rem;
        cursor: pointer; }

      input:checked + label {
        border-color: $blue; }

      input:disabled + label {
        color: grey-c0;
        border-color: $grey-f5; } } }

  &__final {
    display: grid;
    row-gap: 1em;
    padding: 1.5em;
    background-color: $grey-f5;
    border-radius: 0.5em;

    @include media-tablet {
      grid-area: final;
      display: flex;
      justify-content: space-between;
      align-items: center; }

    @include media-tablet-h {
      display: grid;
      justify-content: start;
      align-self: center; }

    &-title {
      @include fs14;
      color: $grey-88; }

    &-time {
      @include fs18;

      @include media-tablet {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        column-gap: 4.1667em; }

      @include media-tablet-h {
        display: block; }

      span {
        display: block;
        margin-top: 0.1667em;
        font-style: 1.3333em;
        font-weight: 800;

        @include media-tablet {
          display: inline;
          margin-top: 0; }

        @include media-tablet-h {
          display: block;
          margin-top: 0.1667em; } } }

    &-btn {
      width: 100%;

      @include media-tablet {
        width: auto; }

      &--disabled {
        background-color: $grey-e5;
        color: $white; } } } }
