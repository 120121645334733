.stand-answer {
  display: grid;
  row-gap: 2.5em;
  padding: 1em;

  border-radius: 0.5em;
  background-color: $grey-f5;

  @include media-tablet {
    padding: 1.5em;
    grid-template-columns: 3.5em 1fr; }

  &>hr {
    margin: 0;
    height: 0.0625em;

    background-color: $grey-e5;
    border: none;

    @include media-tablet {
      display: none; } }

  &__item {
    display: grid;
    row-gap: 1em;

    @include media-tablet {
      grid-template-columns: 2.5em 1fr;
      grid-template-rows: auto 1fr;
      column-gap: 1em;
      grid-template-areas: "user user" ".text";
      row-gap: 0.5em; }

    &:first-of-type {
      @include media-tablet {
        grid-column: 1 / span 2; } }

    &:last-of-type {
      @include media-tablet {
        grid-column: 2; } } }

  &__user {
    display: grid;
    grid-template-columns: 2.5em 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 0.5em;
    grid-template-areas: "img name" "img date";

    @include media-tablet {
      column-gap: 1em;
      grid-template-columns: 2.5em auto minmax(5em, 1fr);
      grid-area: user;
      grid-template-areas: "img name date" "img desc desc"; } }

  &__img {
    grid-area: img;

    img {
      width: 2.5em;
      height: 2.5em;
      display: block;

      border-radius: 50%;
      object-fit: cover;
      object-position: center; } }

  &__name {
    @include fs14;

    grid-area: name;
    font-weight: 800;

    @include media-tablet {
      @include fs16; } }

  &__date {
    @include fs11;

    grid-area: date;
    align-self: center;

    color: #9B9B9B; }

  &__desc {
    display: none;

    @include media-tablet {
      @include fs11;

      display: block;
      grid-area: desc;
      align-self: center;

      color: $grey-88; } }

  &__text {
    @include p14;

    @include media-tablet {
      grid-area: text; } } }
