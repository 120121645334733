.profile-netw-step {
  display: grid;
  row-gap: 4rem;

  &__header {
    @include fs18;

    font-weight: 800;

    span {
      color: $blue; } }

  &__body {
    display: grid;
    row-gap: 1.5rem;

    &-header {
      display: flex;
      column-gap: 1rem;
      justify-content: space-between; } }

  &__footer {
    display: flex;
    column-gap: 1.5rem;

    .btn {
      min-width: 0; } }

  &__title {
    @include reset;
    @include fs18;

    font-weight: 800;

    span {
      color: $blue; }

    &--big {
      @include h24; } }

  &__required {
    @include reset;
    @include fs14;

    color: $blue; }

  &__desc {
    @include reset;
    @include fs18;

    color: $grey-88;

    &--small {
      @include fs14; }

    &--company {
      @include fs14;

      color: $dark-grey;
      padding-left: 2.5rem;
      width: 36.7857em; } }

  &__main {
    display: grid;
    row-gap: 1.5rem;

    &--checkbox-list {
      display: flex;
      flex-wrap: wrap;

      column-gap: 0.75rem;
      row-gap: 0.75rem; }

    &--company-select {
      display: grid;
      column-gap: 2.5rem;
      grid-template-columns: 24.5rem 1fr; }

    &--company {
      display: grid;
      column-gap: 2rem;
      grid-template-columns: 9.375rem 1fr auto;
      align-items: center; }

    &--item-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.75rem;
      row-gap: 0.75rem; }

    &-item {
      padding: 0.3125em 0.5625em;

      color: $grey-88;

      background-color: $grey-f5;
      border-radius: 2rem; } }


  &__company {
    opacity: 0.33;

    &-image {
      width: 9.375rem;
      height: 6.25rem;
      padding: 0.75rem;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center; } }

    &-title {
      @include fs18;

      opacity: 0.33; }

    &-controls {
      display: flex;
      column-gap: 2rem;
      align-items: center; }

    &-status {
      @include fs14;

      padding: 0.7143em;

      color: $grey-88;

      border-radius: 2rem;
      background-color: $grey-e5;

      &--denied {
        color: $blue;

        border: 1px solid $blue; } }

    &-action {
      @include reset;
      @include fs14;

      color: $blue;

      background-color: transparent;
      border: none;

      &:hover {
        text-decoration: underline; } } }

  &__company-create-link {
    @include fs14;

    justify-self: start;
    padding: 0.7143em;

    color: $blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none; } }

  &__autocomplete {
    &-tags {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.75rem;
      row-gap: 0.75rem; }

    &-tag {
      padding: 0.25em 1.625em 0.25em 0.5em;

      color: $dark-grey;

      border: 1px solid $grey-c0;
      border-radius: 2rem;
      background-image: url("../images/autocomplete-close.svg");
      background-repeat: no-repeat;
      background-position: right 0.5em center; } } }
