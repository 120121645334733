.stand-user {
  display: grid;
  row-gap: 0.75em;

  &__body {
    display: grid;
    grid-template-columns: 2.5em 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 0.5em;
    row-gap: 0.25em;
    padding: 0.25em 0; }

  &__name {
    grid-row: 1;
    grid-column: 2; }

  &__position {
    @include fs11;

    grid-row: 2;
    grid-column: 2;

    color: $grey-88; }

  &__img {
    grid-row: 1 / span 2;

    img {
      width: 2.5em;
      height: 2.5em;

      border-radius: 50%;
      object-fit: cover;
      object-position: center; }

    &-placeholder {
      width: 2.5em;
      height: 2.5em;

      background-color: $grey-e5;

      border-radius: 50%; } }

  &__footer {
    display: grid;
    row-gap: 0.75em;

    @include media-tablet {
      row-gap: 0.5em; }

    @include media-tablet-h {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em; }

    @include media-desktop {
      grid-template-columns: 1fr;
      row-gap: 0.5em; }

    @include media-desktop-1440 {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5em; }

    .btn {
      padding: 0;
      height: 2.4286em;
      width: 100%;

      font-weight: 400; } } }

