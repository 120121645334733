.cont {
  margin: 0 auto;
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;

  @include media-tablet {
    padding-left: $tablet-padding;
    padding-right: $tablet-padding; }

  @include media-desktop {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding; }

  @include media-desktop-1440 {
    // width: 90.25em
    padding-left: $desktop-padding;
    padding-right: $desktop-padding; }

  @include media-desktop-1600 {
    width: 100em;
    padding-left: $desktop-padding;
    padding-right: $desktop-padding; } }
