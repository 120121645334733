.event-create {
  display: grid;
  row-gap: 2.5em;
  max-width: 59.6875em;
  width: 100%;
  margin: 0 auto;

  // @include media-desktop
  //   width: 59.6875em
  //   margin: 0 auto

  &__block {
    @extend .cabinet__block;

    padding: 1em;

    @include media-tablet {
      padding: 1.5em; }

    @include media-desktop {
      padding: 2.5em; } }

  &__title {
    display: none;

    @include media-tablet {
      @include reset;
      @include h36;

      display: block; } }

  hr {
    margin: 0;
    height: 0.0625em;

    border: none;
    background-color: $grey-88; } }
