.react-tel-input.phone-input {

  width: 100%;

  .form-control {
    @include p14;

    width: 100%;
    height: 2.375rem;

    border-radius: 2rem;
    border: 1px solid #e5e5e5; }

  .flag-dropdown {
    border-radius: 2rem 0 0 2rem;
    background-color: transparent;
    border: 1px solid #e5e5e5;

    &.open {
      border-radius: 2rem 0 0 2rem;

      .selected-flag {
        background-color: transparent; } }

    .selected-flag {
      background-color: transparent;

      &:hover {
        background-color: transparent; } } } }
