@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?qyvgoc');
  src: url('../fonts/icomoon/icomoon.eot?qyvgoc#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.ttf?qyvgoc') format('truetype'),
  url('../fonts/icomoon/icomoon.woff?qyvgoc') format('woff'),
  url('../fonts/icomoon/icomoon.svg?qyvgoc#icomoon') format('svg') {}
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-calendar:before {
  content: "\e90b"; }

.icon-user-add:before {
  content: "\e900"; }

.icon-cog:before {
  content: "\e901"; }

.icon-logout:before {
  content: "\e902"; }

.icon-microphone:before {
  content: "\e903"; }

.icon-microphone-no:before {
  content: "\e904"; }

.icon-send:before {
  content: "\e906"; }

.icon-polygon:before {
  content: "\e907"; }

.icon-search:before {
  content: "\e908"; }

.icon-camera:before {
  content: "\e909"; }

.icon-user:before {
  content: "\e90a"; }

.icon-facebook:before {
  content: "\e90c"; }

.icon-profitravel:before {
  content: "\e90d"; }

.icon-vk:before {
  content: "\e90e"; }
