.tags {
  width: 100%;
  padding: 0.5em 0;
  background: $color-yellow-new;
  color: $color-black;

  &__list {
    @include lreset;
    display: flex;
    align-items: center;
    column-gap: 0.625em;
    padding-left: 0.625em; }

  &__item {
    @include fs24;
    display: flex;
    align-items: center;
    column-gap: 0.625rem;

    &::after {
      content: '|'; } }

  &__divider {
    width: 1px;
    height: 1.25em;
    background: $color-black; } }
