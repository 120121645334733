@for $i from 1 through 12 {
    .col-#{$i} {
        display: flex;
        flex-direction: column;
        // min-width: min-content
        max-width: 100%;
        width: calc(100% / 12 * #{$i});
        // min-width: min-content
        @media (min-width: 992px) {
            width: calc(100% / 12 * #{$i}); }
        @media (min-width: 895px) {
            min-width: auto; } } }
.col-4, .col-6, .col-8, .col-9, .col-7, .col-5, .col-10 {
    @media (max-width: 900px) {
        width: 100%; } }
.col-3, .col-4, .col-5, .col-2, .col-10 {
    @media (max-width: 600px) {
        width: 100%; } }

