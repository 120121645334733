.page-cutaways-requests {
  margin-top: 2.875em;
  align-self: center;
  width: 100%;
  max-width: calc(#{$header-inner-width} + 5em);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    h1 {
      margin: 0;
      font-size: 2.25em;
      display: flex; } }
  .data {
    line-height: 120%;
    flex-grow: 1; }
  .sidebar {
    width: 200px;
    margin-right: 3.75em;
    a {
      font-family: Roboto;
      font-size: .875em;
      color: black;
      text-decoration: none;
      font-weight: bold; }
    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin-top: 2.375em;
      li {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        a {
          color: black;
          text-decoration: none;
          font-weight: normal;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-size: 1.285em;
          padding: .61em 0;
          &.active {
            background: white;
            font-weight: bold; } } } } } }
