.cabinet {
  display: grid;
  grid-template-columns: 15.3125em 1fr;
  grid-template-rows: 100%;
  min-width: 1280px;
  flex-grow: 1;

  color: $dark-grey;

  background-color: $grey-f5;

  &__netw-profile {
    display: grid;
    row-gap: 2.5rem;
    max-width: 79.625rem;
    margin: 0 auto; }

  &__side {
    background-color: $white; }

  &__main {
    padding: 2.5em; }

  &__block {
    padding: 2.5em;

    background-color: $white;
    border-radius: 1.25rem; }

  &__title {
    @include reset;

    @include h36;

    &--networking {
      @include fs16;

      display: grid;
      grid-template-columns: 1fr 27.375rem;

      h1 {
        @include reset;
        @include h36; } }

    &-contacts {
      @include fs14;

      display: grid;
      row-gap: 0.25rem;

      font-weight: 400;

      &-content {
        font-weight: 700; }

      &-support {
        display: grid;
        row-gap: 0.25rem;

        color: $grey-c0;

        a {
          color: $blue; }

        p {
          @include reset; } } } } }



