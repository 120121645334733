.netw-soon {
  display: grid;
  row-gap: 5em;

  background-color: $grey-f5;

  @include media-tablet-h {
    row-gap: 0; }

  .cont {
    max-width: 95em;

    @include media-desktop {
      padding: 0 2.5em; } }

  &__header {
    padding: 2.5em 0;

    background-color: $white;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;

    @include media-tablet {
      border-radius: 0px 0px 1em 1.5em; }

    @include media-desktop {
      padding: 5em 0; } }

  &__title {
    @include reset;
    @include h24;

    @include media-tablet {
      @include h48; }

    @include media-desktop {
      @include h64; } }

  &__body {
    position: relative;

    padding-bottom: 3.5em;

    &::after {
      content: "";

      position: absolute;
      width: 100%;
      height: 15em;

      bottom: 0;
      left: 0;
      right: 0;

      background-color: $red; }

    .cont {
      display: grid;
      row-gap: 5em;

      @include media-tablet-h {
        row-gap: 7.5em; } } }

  &__main {
    @include media-tablet-h {
      display: grid;
      grid-template-columns: 39% 1fr;
      column-gap: 3.32%;
      align-items: center;
      margin-top: -3.125em; }

    @include media-desktop {
      grid-template-columns: 40.9% 1fr;
      column-gap: 3.1%; }

    @include media-desktop-1440 {
      grid-template-columns: 41.9798% 1fr;
      column-gap: 6.5%; }

    @include media-desktop-1600 {
      grid-template-columns: 42.6316% 1fr;
      column-gap: 8.48%; }

    &-content {
      display: grid;
      row-gap: 1em; }

    &-title {
      @include fs18;

      font-weight: 800;

      @include media-tablet {
        @include h24; }

      @include media-tablet-h {
        @include h36; } }

    &-desc {
      @include p14;

      @include media-tablet {
        @include p18; } }

    &-img {
      display: none;

      @include media-tablet-h {
        position: relative;

        display: block;
        // width: 49.125em
        height: 29.4375em; }

      img {
        @include media-tablet-h {
          position: absolute;
          left: 0;
          top: 0;

          width: 49.125em;
          height: 29.4375em;

          max-width: 49.125em; } } } }

  &__tagline {
    display: grid;
    row-gap: 1.5em;

    @include media-tablet {
      grid-template-columns: 1fr auto;
      column-gap: 7.5em;
      align-items: center; }

    &-text {
      @include h24;

      @include media-tablet {
        @include h36; }

      @include media-tablet-h {
        @include h48; }

      span {
        color: $red; } }

    &-icon {
      display: grid;
      justify-content: center;

      img {
        width: 3em;

        @include media-tablet {
          width: 6em; } } } }

  &__cards {
    position: relative;
    z-index: 5;

    display: grid;
    row-gap: 2.5em;
    padding: 2.5em 1.25em;

    background-color: $white;
    border-radius: 0.75em;
    box-shadow: 0px 0.25em 2.5em rgba(0, 0, 0, 0.15);

    @include media-tablet-h {
      padding: 1.75em 3.5em;
      grid-template-columns: 44.19% 1fr;
      column-gap: 3.5em;
      align-items: center; }

    @include media-tablet-h {
      padding: 3.9375em;
      column-gap: 2.125em; }

    &-content {
      display: grid;
      row-gap: 1.5em; }

    &-title {
      @include h24;

      @include media-tablet {
        @include h36; } }

    &-desc {
      @include fs18;

      color: $red;
      font-weight: 800;

      @include media-tablet {
        @include h24; } }

    &-arrow {
      position: relative;

      background-color: $red;
      height: 0.125em;
      margin-top: 0.625em;

      @include media-tablet {
        margin-top: 0.25em; }

      &::after {
        content: "";

        width: 9px;
        height: 16px;

        position: absolute;
        right: -3px;
        bottom: 1px;
        transform: translateY(50%);

        background-image: url("../images/red-arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;

        @include media-tablet-h; } }

    &-list {
      @include lreset;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1.25em;
      column-gap: 1.25em;

      @include media-tablet {
        grid-template-columns: repeat(3, 1fr); }

      @include media-tablet-h {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1em;
        column-gap: 1em; }

      @include media-desktop {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 1.25em;
        column-gap: 1.25em; } }

    &-item {
      a {
        @include fs11;

        display: grid;
        align-items: center;

        padding: 0 1em;
        height: 10em;

        color: $dark-grey;
        text-align: center;
        font-weight: 800;

        background-color: $grey-f5;
        border-radius: 0.7273em;

        @include media-tablet {
          @include fs16;

          padding: 0 1.25em;
          height: 6.875em; } } } } }
