@for $i from -12 through 12 {
  .mt-#{$i} {
    margin-top: #{$i}em !important; }
  .mr-#{$i} {
    margin-right: #{$i}em !important; }
  .ml-#{$i} {
    margin-left: #{$i}em !important; }
  .mb-#{$i} {
    margin-bottom: #{$i}em !important; }
  .md-#{$i} {
    margin: #{$i}em !important; }
  .mx-#{$i} {
    margin-left: #{$i}em !important;
    margin-right: #{$i}em !important; }
  .my-#{$i} {
    margin-top: #{$i}em !important;
    margin-bottom: #{$i}em !important; }

  .mt-0#{$i} {
    margin-top: calc(#{$i}em / 10); }
  .mr-0#{$i} {
    margin-right: calc(#{$i}em / 10); }
  .ml-0#{$i} {
    margin-left: calc(#{$i}em / 10); }
  .mb-0#{$i} {
    margin-bottom: calc(#{$i}em / 10); }
  .md-0#{$i} {
    margin: calc(#{$i}em / 10); }
  .mx-0#{$i} {
    margin-left: calc(#{$i}em / 10);
    margin-right: calc(#{$i}em / 10); }
  .my-0#{$i} {
    margin-top: calc(#{$i}em / 10);
    margin-bottom: calc(#{$i}em / 10); }

  .mt-1-#{$i} {
    margin-top: calc(#{$i}em / 10 + 1em); }
  .mr-1-#{$i} {
    margin-right: calc(#{$i}em / 10 + 1em); }
  .ml-1-#{$i} {
    margin-left: calc(#{$i}em / 10 + 1em); }
  .mb-1-#{$i} {
    margin-bottom: calc(#{$i}em / 10 + 1em); }
  .md-1-#{$i} {
    margin: calc(#{$i}em / 10 + 1em); }
  .mx-1-#{$i} {
    margin-left: calc(#{$i}em / 10 + 1em);
    margin-right: calc(#{$i}em / 10 + 1em); }
  .my-1-#{$i} {
    margin-top: calc(#{$i}em / 10 + 1em);
    margin-bottom: calc(#{$i}em / 10 + 1em); }

  .mt--0#{$i} {
    margin-top: calc(-#{$i}em / 10); }
  .mr--0#{$i} {
    margin-right: calc(-#{$i}em / 10); }
  .ml--0#{$i} {
    margin-left: calc(-#{$i}em / 10); }
  .mb--0#{$i} {
    margin-bottom: calc(-#{$i}em / 10); }
  .md--0#{$i} {
    margin: calc(-#{$i}em / 10); }
  .mx--0#{$i} {
    margin-left: calc(-#{$i}em / 10);
    margin-right: calc(-#{$i}em / 10); }
  .my--0#{$i} {
    margin-top: calc(-#{$i}em / 10);
    margin-bottom: calc(-#{$i}em / 10); }

  .mt--1-#{$i} {
    margin-top: calc(-#{$i}em / 10 - 1em); }
  .mr--1-#{$i} {
    margin-right: calc(-#{$i}em / 10 - 1em); }
  .ml--1-#{$i} {
    margin-left: calc(-#{$i}em / 10 - 1em); }
  .mb--1-#{$i} {
    margin-bottom: calc(-#{$i}em / 10 - 1em); }
  .md--1-#{$i} {
    margin: calc(-#{$i}em / 10 - 1em); }
  .mx--1-#{$i} {
    margin-left: calc(-#{$i}em / 10 - 1em);
    margin-right: calc(-#{$i}em / 10 - 1em); }
  .my--1-#{$i} {
    margin-top: calc(-#{$i}em / 10 - 1em);
    margin-bottom: calc(-#{$i}em / 10 - 1em); } }

.md-auto {
  margin: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-top: auto; }

.mx-auto {
  margin-top: 0 auto; }

.my-auto {
  margin-top: auto 0; }
