// @import "~react-datepicker/dist/react-datepicker.css"
@import "packages/index";
@import "imports/index";
@import "configs/index";
@import "mixins/index";
@import "components/index";
@import "helpers/index";
@import "pages/index";

div, a, nav, ul, li {
  box-sizing: border-box {
 } }    // transition: .3s
.company-user-avatar-fix {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5); }
.tac {
  text-align: center; }
