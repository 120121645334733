.page-cutaways-list {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 338px;
  h1 {
    margin: 0; }
  &__navigation {
    display: flex;
    margin-top: 40px;
    font-size: 14px;
    line-height: 19px;
    &-btn {
      display: inline-flex;
      align-items: center;
      min-width: 166px;
      padding: 8px 20px;
      background: $grey-e5;
      border-radius: 20px 20px 0 0;
      &.active {
        background: $white;
        .page-cutaways-list__navigation-count {
          color: $blue;
          border: $blue 1px solid; } }
      &:last-child {
        margin-left: auto; } }
    &-count {
      height: 19px;
      padding: 0 10px;
      margin-left: 8px;
      font-size: 11px;
      line-height: 17px;
      font-weight: 800;
      color: $grey-c0;
      border: $grey-c0 1px solid;
      border-radius: 33px; }
    &-new {
      height: 19px;
      padding: 1px 8px;
      margin-left: 8px;
      font-size: 11px;
      line-height: 17px;
      font-weight: 800;
      color: $white;
      background: $blue;
      border-radius: 2.0625em; } }
  &__content {
    justify-self: center;
    max-width: 937px;
    width: 100%; }

  .cutaways {
    padding: 8px 0;

    background: $white;
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    &__message {
      border-radius: 8px;
      background: #F0F0F0;
      padding: 24px;
      margin: 24px 40px;
      &-col {
        font-size: 18px;
        font-weight: bold; }
      &-text {
        font-size: 14px;
        margin-top: 12px;
        max-width: 326px;
        span {
          color: $blue;
          text-decoration: underline dotted; }
        &-width {
          max-width: 100%;
          span {
            text-decoration: none; } } } }

    &__warning {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 32px 24px;
      padding: 24px;
      border: $blue 1px solid;
      border-radius: 8px;
      &-col {
        &:not(:last-child) {
          margin-right: 40px; } } }
    &__download {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 32px 24px;
      padding: 24px;
      background: $grey-f0;
      border-radius: 8px;
      &-col {
        &:not(:last-child) {
          margin-right: 40px; } }
      .btn {
        padding: 0 12px;
        white-space: nowrap; } } } }
