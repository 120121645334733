.page-chat {
  margin-top: 2.875em;
  align-self: center;
  width: 100%;
  max-width: calc(#{$header-inner-width} + 5em);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .left, .right {
    margin: 2px;
    background: white;
    flex-grow: 1;
    display: flex;
    border: 1px solid #E4E4E4; }
  .left {
    .filters, .chats {
      flex-grow: 1; }
    .filters {
      background: rgba(34, 34, 34, 0.9);
      width: 7em;
      // height: 100%
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 10.625em;
        li {
          margin: 0;
          padding: 0;
          padding-left: 1.6875em;
          margin-bottom: .79em;
          a {
            color: $white;
            text-decoration: none; } } } }
    .chats {
      padding-left: 2.875em;
      h2 {
        margin-left: 1em;
        font-weight: 900;
        font-size: 1.5em;
        margin-bottom: 4.6875em; }
      h3 {
        padding-left: 1.5em;
        font-weight: 900;
        font-size: 1.25em; }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          a {
            height: 4.625em;
            display: flex;
            align-items: center;
            width: 100%;
            text-decoration: none;
            .avatar {
              margin-left: 1.875em;
              img {
                width: 3.4375em;
                height: 3.4375em;
                min-width: 3.4375em;
                min-height: 3.4375em;
                border-radius: 100%; } }
            .name {
              margin-left: 1.875em;
              font-size: 1.125em;
              color: black; }
            &.selected {
              background: #F6F6F6; } } } } } }
  .right {
    display: flex;
    flex-direction: column;
    .chat {
      flex-grow: 1;
      display: flex; }
    .message-form {
      border-top: 1px solid #E4E4E4;
      padding: 1.6875em 2.5em;
      textarea {
        flex-grow: 1; } } } }
