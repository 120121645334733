.profile-netw-status {
  display: flex;
  justify-content: space-between;

  &__main {
    display: grid;
    row-gap: 0.5rem;
    width: 25rem; }

  &__aside {
    display: grid;
    row-gap: 0.75rem;
    width: 15.9375rem; }

  &__title {
    @include fs18;
    @include reset;

    color: $blue;
    font-weight: 800; }

  &__desc {
    @include fs14;
    @include reset; }

  &__disclaimer {
    @include fs11;
    @include reset;

    color: $grey-88; } }
