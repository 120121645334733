.event-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5em 1.25em;

  background-color: $grey-f5;

  @include media-tablet {
    padding: 2.5em 1.875em; } }
