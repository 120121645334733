.header--index {
  display: block;
  height: auto;
  background: $grey-e5;
  z-index: 0;

  @include media-tablet {
    background: $grey-e5; }

  .header {
    &__main {
      position: relative;
      padding: 0 1.25em;

      @include media-tablet {
        display: flex;
        height: 39.0625em;
        padding: 0; }

      @include media-desktop {
        height: 40.625em; }

      @include media-desktop-1440 {
        height: 45.625em; }

      @include media-desktop-1600 {
        position: relative;
        height: 45.625em; }

      @include media-desktop-1920 {
        width: 120em;
        margin: 0 auto; }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 38.5em;
        height: 39.0625em;
        background: url(@public/images/header/main-left-before-tablet.svg) no-repeat;
        z-index: 1;

        @include media-tablet {
          display: none; }

        @include media-desktop-1920 {
          display: block;
          width: 50%;
          height: 100%;
          transform: translateX(-100%);
          background: $color-yellow-new;
          z-index: 10; } }

      &-left {
        position: relative;
        padding-top: 3.75em;
        z-index: 10;

        @include media-tablet {
          display: flex;
          flex-direction: column;
          padding-top: 5.125em;
          padding-left: 2em; }

        @include media-desktop {
          position: absolute;
          top: 0;
          left: 0;
          padding-top: 4.4375em;
          padding-left: 5em; }

        @include media-desktop-1440 {
          padding-top: 5.5em; }

        @include media-desktop-1920 {
          padding-left: 15em; }

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          display: none;
          width: 38.5em;
          height: 39.0625em;
          background: url(@public/images/header/main-left-before-tablet.svg) no-repeat;
          z-index: 20;

          @include media-tablet {
            display: block; }

          @include media-desktop {
            top: 0;
            bottom: auto;
            left: -1.25em;
            width: 38.5em;
            height: 40.625em;
            background-image: url(@public/images/header/main-left-before-desktop.svg); }

          @include media-desktop-1440 {
            width: 43.25em;
            height: 45.625em;
            background-image: url(@public/images/header/main-left-before-desktop-1440.svg); }

          @include media-desktop-1600 {
            left: 0;
            width: 44.9375em;
            background-image: url(@public/images/header/main-left-before-desktop-1600.svg); }

          @include media-desktop-1920 {
            width: 53.25em;
            background-image: url(@public/images/header/main-left-before-desktop-1920.svg); } }

        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 8em;
          display: none;
          width: 72.4375em;
          height: 22.25em;
          background: url(@public/images/header/main-left-after-tablet.svg) no-repeat;
          z-index: 10;

          @include media-tablet {
            display: block; }

          @include media-tablet-h {
            left: 7em;
            width: 74.125em;
            height: 22.25em;
            background-image: url(@public/images/header/main-left-after-tablet-h.svg); }

          @include media-desktop {
            display: none; } }

        &-top {
          margin-bottom: 2em;

          @include media-tablet {
            margin-bottom: 2.5em;
            z-index: 50; } }

        &-date {
          @include fs18;
          margin-bottom: 0.25rem;
          font-weight: 700;
          color: $color-black;

          @include media-tablet {
            @include h24;
            margin-bottom: 0; } }

        &-logo {
          margin-bottom: 0.25em;

          @include media-tablet {
            margin-bottom: 1.25em; }

          img {
            display: block;
            width: 17.375em;

            @include media-tablet {
              width: 21.625em;
              max-width: none; }

            @include media-desktop {
              width: 23.75em; }

            @include media-desktop-1440 {
              width: 27.5em; } } }

        &-text {
          @include h36;
          width: 21.625rem;
          text-transform: uppercase;
          color: $color-black;

          @include media-tablet {
            @include h48; } }

        &-bottom {
          @include media-tablet {
            width: 24.375em;
            z-index: 50; }

          @include media-tablet-h {
            margin: 0;
            width: 100%; } }

        &-desc {
          @include fs24;
          display: none;
          margin-bottom: 2.5rem;
          color: $color-black;

          @include media-tablet {
            display: block;
            margin-bottom: 2.5rem; }

          @include media-tablet-h {
            width: 25.75rem;
            margin-bottom: 2.5rem; }

          @include media-desktop-1440 {
            width: 27.5rem; } }

        &-reg {
          .btn {
            @include h24;
            width: 17.375rem;
            min-width: auto;
            height: 3.875rem;

            @include media-tablet {
              width: 100%; }

            @include media-tablet-h {
              width: 23.6875rem; }

            @include media-desktop-1440 {
              width: 27.5rem; } } } }

      &-right {
        position: relative;
        height: 10.8125em;

        @include media-tablet {
          display: none; }

        @include media-desktop {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden; }

        @include media-desktop-1600 {
          background: $white; }

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: -11.25em;
          width: 26.8125em;
          height: 6.625em;
          background: url(@public/images/header/slider-before-mobile.svg) no-repeat;
          z-index: 1;

          @include media-tablet {
            display: none; } }

        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 2.25em;
          width: 25.1875em;
          height: 8.875em;
          background: url(@public/images/header/slider-after-mobile.svg) no-repeat;
          z-index: 2;

          @include media-tablet {
            display: none; } } }

      &-slider {
        position: relative;
        display: none;
        height: 100%;
        background: url(@public/images/header/preview.jpg) no-repeat center center;
        background-size: cover;

        @include media-desktop {
          display: block; }

        .swiper-container {
          height: 100%; } }

      &-slide {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; }

        &-descr {
          @include fs28;
          position: absolute;
          top: 8.75rem;
          left: 47%;
          width: 23.75rem;
          font-weight: 500;
          color: $color-black;
          z-index: 20;

          @include media-desktop-1440 {
            top: 11.25rem;
            left: 48%; }

          @include media-desktop-1600 {
            left: 50%; }

          &::before {
            content: '';
            position: absolute;
            top: -2.5rem;
            left: 0;
            width: 1.6875rem;
            height: 1.3125rem;
            background: url(@public/images/icon-quote.svg) no-repeat center center; } }

        &-signature {
          @include fs24;
          position: absolute;
          top: 29.375rem;
          left: 47%;
          display: flex;
          flex-direction: column;
          color: $white;
          opacity: 0.7;

          @include media-desktop-1440 {
            top: 35.5625rem;
            left: 48%; }

          @include media-desktop-1600 {
            left: 50%; } }

        &-expert {
          font-weight: 600; }

        &-posotion {
          font-weight: 500; } } } } }
