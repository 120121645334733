.cutaway {
  display: grid;
  align-items: center;
  grid-gap: 40px;
  padding: 0 40px 32px;
  margin-top: 32px;
  width: 100%;

  font-size: 14px;
  line-height: 1.3;

  &:not(:last-child) {
    border-bottom: $grey-e5 1px solid; }
  &_saved {
    @extend .cutaway;
    grid-template-columns: minmax(197px, 383px) minmax(170px, 1fr) 90px; }

  &_outbox {
    @extend .cutaway;
    grid-template-columns: minmax(197px, 383px) minmax(170px, 1fr) 145px; }

  &_inbox {
    @extend .cutaway;
    grid-template-columns: minmax(197px, 383px) minmax(170px, 1fr) 145px; }
  figure {
    margin: 0;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
      object-position: center; } }
  p {
    margin: 0; }
  &__persona {
    display: grid;
    grid-gap: 4px 12px;
    max-width: 243px;
    @include media-desktop-1440 {
      grid-template-columns: auto 1fr; }
    &-photo {
      overflow: hidden;
      width: 40px;
      height: 40px;
      margin-bottom: 8px !important;
      border-radius: 50%;
      img {
        object-fit: cover; }
      .bg-avatar {
        width: 100%;
        height: 100%;
        background: #F5F5F5; }
      @include media-desktop-1440 {
        grid-row: 1 / span 2;
        margin: 0; } }
    &-position {
      font-size: 11px;
      line-height: 1.35;
      color: $grey-88; } }
  &__contacts {
    display: grid;
    grid-gap: 8px; }
  &__company {
    width: 90px;
    height: 60px;

    &-img {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 7px;
      justify-content: center;
      align-items: center;

      border: $grey-e5 1px solid;
      border-radius: 4px; }

    img {
      object-fit: scale-down;
      object-position: center; } }

  &__buttons {
    display: grid;
    grid-gap: 12px;
    padding-left: 32px;
    border-left: $grey-e5 1px solid;
    .btn {
      width: 100%;
      padding: 0 12px;
      max-width: 116px;
      min-width: initial; } } }
