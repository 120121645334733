.stand-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: 33.75em;

  @include media-desktop {
    padding-left: 40.5vw;
    padding-top: 3.9vw; }

  &__video,
  &__image {
    width: 40.2031vw;
    height: 26.7448vw;

    object-fit: cover;
    object-position: center; }

  &__video-link {
    display: none;

    @include media-desktop {
      position: absolute;
      left: 40.5vw;
      top: 3.9vw;

      display: block;
      width: 40.2031vw;
      height: 26.7448vw;

      z-index: 50;

      background-image: url("../images/play.svg");
      background-repeat: no-repeat;
      background-size: 14% 14%;
      background-position: center; } }

  &__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;

    @include media-desktop;

    &--mobile {
      // background-image: url("../images/stand-bg.jpg")
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @include media-desktop {
        display: none; } }

    &--desktop {
      display: none;
      @include media-desktop {
        display: block;
        height: 42.1875vw;

        background-image: url("../images/stand-bg-general.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top left; } } }

  &--no-mobile {
    height: 0;
    overflow: hidden;

    @include media-desktop {
      height: 33.75em;
      overflow: visible; } }

  &--no-desktop {
    height: 33.75em;
    overflow: visible;

    @include media-desktop {
      height: 0;
      overflow: hidden; } }

  &--no-mobile {
    &.stand-header--no-desktop {
      height: 0;
      overflow: hidden; } } }
