.company-edit {
  display: grid;
  grid-template-columns: 1fr 23.625em;
  grid-template-rows: auto 1fr;
  column-gap: 2em;
  row-gap: 2.5em;
  max-width: 79.625em;
  margin: 0 auto;

  &__col {
    display: grid;
    row-gap: 2em;
    grid-row: 2;

    &--left {
      grid-column: 1; }

    &--right {
      grid-column: 2;
      align-self: start; } }

  &__title {
    @include h24; }

  .textarea__input {
    height: 13.1429em; }

  &__form {
    .form {
      row-gap: 2em;

      &__footer {
        display: flex; } }

    .btn {
      display: inline-block;
      min-width: 16.1429em;
      width: auto; }

    &--contacts {
      hr {
        @include media-desktop-1600 {
          display: none; } }

      .form {
        &__body {
          @include media-desktop-1600 {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 3.0625em;
            row-gap: 2em; } }

        &__group {
          &--span-2 {
            @include media-desktop-1600 {
              grid-column: auto / span 2; } }

          &--links {
            @include media-desktop-1600 {
              position: relative;

              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 3.0625em;
              grid-column: 1 / span 2; }

            &::before {
              @include media-desktop-1600 {
                content: "";

                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;

                width: 1px;
                background-color: $grey-88; } } }

          &--address {
            position: relative;

            &::after {
              @include media-desktop-1600 {
                content: "";

                position: absolute;
                top: 0;
                bottom: 0;
                right: -1.5625em;

                width: 1px;
                background-color: $grey-88; } } } } } } }

  &__avatar {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12.5em;

    color: $grey-88;

    border: 0.0625em dashed $grey-c0;
    background-color: $grey-f5;
    border-radius: 0.25em;

    &--uploaded {
      padding: 0.75em;

      background-color: transparent;
      border: 0.0625em solid $grey-e5;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center; } }

    span {
      @include fs14;

      display: block;
      margin-top: 0.5714em; }

    input {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;

      width: 100%;

      opacity: 0; } }

  &__spec {
    display: grid;
    row-gap: 1em;

    &-inputs {
      padding-top: 1em; }

    &-desc {
      @include fs14;

      color: $grey-88; } }

  &__invite {
    form {
      row-gap: 2em; }

    &-submit {
      width: 100%; } }

  &__company-name {
    @include fs18;

    font-weight: 800; } }
