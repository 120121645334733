@for $i from 0 through 12 {
    .pd-#{$i} {
        padding: #{$i}em; }
    .pt-#{$i} {
        padding-top: #{$i}em; }
    .pr-#{$i} {
        padding-right: #{$i}em; }
    .pl-#{$i} {
        padding-left: #{$i}em; }
    .pb-#{$i} {
        padding-bottom: #{$i}em; }
    .py-#{$i} {
        padding-top: #{$i}em;
        padding-bottom: #{$i}em; }
    .px-#{$i} {
        padding-left: #{$i}em;
        padding-right: #{$i}em; }

    .pt-0#{$i} {
        padding-top: calc(#{$i}em / 10); }
    .pr-0#{$i} {
        padding-right: calc(#{$i}em / 10); }
    .pl-0#{$i} {
        padding-left: calc(#{$i}em / 10); }
    .pb-0#{$i} {
        padding-bottom: calc(#{$i}em / 10); }
    .pd-0#{$i} {
        padding: calc(#{$i}em / 10); }
    .py-0#{$i} {
        padding-top: calc(#{$i}em / 10);
        padding-bottom: calc(#{$i}em / 10); }
    .px-0#{$i} {
        padding-left: calc(#{$i}em / 10);
        padding-right: calc(#{$i}em / 10); } }
