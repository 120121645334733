table.table {
  border-spacing: 0px;
  border-collapse: collapse;
  tr {
    td {
      padding: 0.95em 1.25em;
      box-sizing: border-box;
      border-bottom: 1px solid #CCCCCC;
      font-size: .875em;
      a {
        color: #000000; }
      &.right {
        text-align: right; } }
    &:last-child {
      td {
        border: none; } } }
  thead {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    tr {
      td {
        padding: 1.375em 1.25em;
        white-space: nowrap;
        font-size: 1.125em; }
      &:last-child {
        td {
          border-bottom: 1px solid #CCCCCC; } } } }
  tbody {} }
