.page-event-show {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  h1 {
    width: 100%; }
  .form {
    display: flex;
    flex-direction: column; }
  .row {
    display: flex;
    width: 100%;
    flex-grow: 1; }
  .chat {
    border-radius: 1em;
    padding: .5em;
    margin: .5em;
    background: $white;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-shadow: 3px 7px 16px -9px rgba(0,0,0,0.39);
    border-radius: 1em;
    .messages {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-height: 800px;
      overflow-y: scroll;
      .message {
        margin: .2em;
        padding: 25px 20px;
        background: #E4E4E4;
        border-radius: 5px;
        font-size: 14px;
        .name {
          font-weight: bold;
          line-height: 120%;
          width: 100%;
          margin-bottom: 20px; }
        &.self {
          color: $white;
          background: #2D9CDB; } } }
    .panel {
      border-radius: 1em;
      box-sizing: border-box;
      padding: .5em;
      background: #F2F2F2;
      display: flex;
      width: 100%;
      textarea {
        flex-grow: 1;
        margin-right: .5em; } } }
  .video {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: .5em;
    margin: .5em;
    background: black;
    border-radius: 1em;
    .control {
      display: flex;
      justify-content: space-evenly;
      padding: 1em 0;
      font-size: 1.2em;
      a {
        text-decoration: none;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1em;
        background: #dc3545;
        border-radius: 5em;
        &.active {
          background: #007bff; }
        &.green {
          background: #28a745; } } } } }
