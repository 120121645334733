.stand-partners {
  display: grid;
  row-gap: 1em;

  &__title {
    @include h24;

    margin-bottom: 0.6667em;

    @include media-tablet {
      @include h36;

      margin-bottom: 0.4444em; } }

  &__grid {
    display: grid;
    row-gap: 2em;

    @include media-tablet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2em; }

    @include media-tablet-h {
      grid-template-columns: repeat(3, 1fr); }

    @include media-desktop-1600 {
      grid-template-columns: repeat(4, 1fr); } }

  &__item {
    padding: 1.5em;

    background-color: $white;
    border-radius: 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-logo {
      width: 7.5em;
      height: 5em;
      padding: 0.5625em;

      border: 0.0625em solid $grey-e5;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center; } }

    &-title {
      @include fs14;

      margin-top: 1.2857em;

      font-weight: 800; }

    &-buttons {
      margin-top: 1.875em;

      .btn {
        width: 100%;
        padding: 0;
        margin-top: 0.5em;

        &--grey {
          font-weight: 400;
          color: $grey-88;

          background-color: $grey-e5; } } } }

  &__more-link {
    @include fs14;

    display: flex;
    padding: 1.2857em 0;
    align-items: center;
    justify-content: center;

    color: $dark-grey;

    background-color: $grey-e5;
    border-radius: 0.5714em;

    img {
      margin-left: 1.1429em; } } }
