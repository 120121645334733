.cabinet-rating {
  display: grid;
  row-gap: 2.5em;

  color: $dark-grey;

  &__main {
    display: grid;
    grid-template-columns: 1fr 21.875em;
    column-gap: 2em;
    align-items: start; }

  &__col {
    background-color: $white;
    border-radius: 0.5em;

    &--left {
      padding: 2.5em; }

    &--right {
      position: relative;

      padding: 10.8125em 2.5em 2.5em;

      box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
      background-image: url("../images/loyalty-bg.svg");
      background-repeat: no-repeat;
      background-position: top; } }

  &__list {
    @include lreset; }

  &__item {
    padding: 1.5em 0;
    display: grid;
    grid-template-columns: 7.5em 1fr 4.5625em;
    column-gap: 3.5em;
    align-items: center;

    border-bottom: 0.0625em solid $grey-e5;

    &:first-child {
      padding-top: 0; }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }

    &-date {
      @include fs14;

      color: $grey-88; }

    &-content {
      display: grid;
      row-gap: 0.25em; }

    &-title {
      @include fs11;

      font-weight: 800; }

    &-desc {
      @include fs14; }

    &-count {
      @include h24;

      font-weight: 800;
      color: $red;
      text-align: right; } }

  &__loyalty {
    &-list {
      display: grid;
      row-gap: 1em; }

    &-item {
      display: grid;
      column-gap: 1em;
      grid-template-columns: 1fr auto;
      align-items: center;

      &-left {
        @include fs14; }

      &-right {
        @include h24; }

      &--score {
        .rating__loyalty-item-right {
          color: $red; } }

      &--position {
        .rating__loyalty-item-right {
          color: $dark-grey;

          span {
            font-size: 0.5833em;
            color: $grey-88;
            font-weight: 400; } } } } } }
