
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-100.eot');
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100.svg#Roboto') format('svg') {} }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-100italic.eot');
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100italic.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100italic.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100italic.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-100italic.svg#Roboto') format('svg') {} }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-300.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-300italic.eot');
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300italic.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300italic.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300italic.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-300italic.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-regular.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-regular.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-regular.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-regular.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-italic.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-italic.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-italic.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-italic.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-500.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-500italic.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500italic.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500italic.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500italic.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-500italic.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-700.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-700italic.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700italic.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700italic.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700italic.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-700italic.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-900.eot');
  src: local('Roboto Black'), local('Roboto-Black'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900.svg#Roboto') format('svg') {} }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/roboto/roboto-v20-cyrillic_latin-900italic.eot');
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900italic.woff2') format('woff2'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900italic.woff') format('woff'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900italic.ttf') format('truetype'),
  url('../fonts/roboto/roboto-v20-cyrillic_latin-900italic.svg#Roboto') format('svg') {} }




@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-LightIt.eot');
  src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
  url('../fonts/proxima/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-LightIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-LightIt.ttf') format('truetype') {}
  font-weight: 300;
  font-style: italic; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-Extrabld.eot');
  src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
  url('../fonts/proxima/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-Extrabld.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-Extrabld.ttf') format('truetype') {}
  font-weight: 800;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-Regular.eot');
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
  url('../fonts/proxima/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-Regular.ttf') format('truetype') {}
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-Semibold.eot');
  src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
  url('../fonts/proxima/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-Semibold.ttf') format('truetype') {}
  font-weight: 600;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-ThinIt.eot');
  src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
  url('../fonts/proxima/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-ThinIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-ThinIt.ttf') format('truetype') {}
  font-weight: 100;
  font-style: italic; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-SemiboldIt.eot');
  src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
  url('../fonts/proxima/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-SemiboldIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-SemiboldIt.ttf') format('truetype') {}
  font-weight: 600;
  font-style: italic; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-RegularIt.eot');
  src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
  url('../fonts/proxima/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-RegularIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-RegularIt.ttf') format('truetype') {}
  font-weight: normal;
  font-style: italic; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-Black.eot');
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
  url('../fonts/proxima/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-Black.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-Black.ttf') format('truetype') {}
  font-weight: 900;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-Bold.eot');
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
  url('../fonts/proxima/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-Bold.ttf') format('truetype') {}
  font-weight: bold;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNovaT-Thin.eot');
  src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
  url('../fonts/proxima/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNovaT-Thin.woff') format('woff'),
  url('../fonts/proxima/ProximaNovaT-Thin.ttf') format('truetype') {}
  font-weight: 100;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-BoldIt.eot');
  src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
  url('../fonts/proxima/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-BoldIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-BoldIt.ttf') format('truetype') {}
  font-weight: bold;
  font-style: italic; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-ExtrabldIt.eot');
  src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
  url('../fonts/proxima/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-ExtrabldIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-ExtrabldIt.ttf') format('truetype') {}
  font-weight: 800;
  font-style: italic; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-Light.eot');
  src: local('Proxima Nova Light'), local('ProximaNova-Light'),
  url('../fonts/proxima/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-Light.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-Light.ttf') format('truetype') {}
  font-weight: 300;
  font-style: normal; }


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima/ProximaNova-BlackIt.eot');
  src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
  url('../fonts/proxima/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/ProximaNova-BlackIt.woff') format('woff'),
  url('../fonts/proxima/ProximaNova-BlackIt.ttf') format('truetype') {}
  font-weight: 900;
  font-style: italic; }
