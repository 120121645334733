.textarea {
  position: relative;

  width: 100%;

  textarea,
  &__input {
    @extend .input[type="text"];

    height: auto;
    width: 100%;
    min-height: 2.7143em;
    resize: none;
    padding: 0.8571em;
    padding-bottom: 1.5em;

    font-family: $base-font; }

  &__counter {
    @include fs11;

    position: absolute;
    right: 1.9em;
    bottom: 1em;

    color: $grey-c0; } }
