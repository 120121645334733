.stand-title {
  @include media-tablet {
    display: grid;
    grid-template-columns: 11.25em 1fr auto;
    column-gap: 2.5em; }

  &--gold {
    position: relative;

    padding-top: 19em;

    overflow: hidden;

    @include media-tablet {
      padding-top: 24em; }

    @include media-desktop {
      grid-template-columns: 1fr;
      row-gap: 2.5em;
      padding-top: 2.5em;
      padding-right: 50%;

      .stand-title__content {
        padding-right: 2.5em; } } }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    height: 17.5em;

    // background-image: url("../images/stand-bg.jpg")

    @include media-tablet {
      height: 22.5em; }

    @include media-desktop {
      right: 0;
      top: 0;
      bottom: 0;
      left: auto;

      width: 50%;
      height: auto; }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center; } }

  &__logo {
    width: 11.25em;
    height: 11.25em;
    padding: 0.875em;

    border: 0.0625em solid $grey-e5;
    border-radius: 0.25em;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center; } }

  &__content {
    display: flex;
    flex-direction: column;

    @include media-tablet {
      align-items: flex-start; }

    .btn {
      margin-top: auto;

      @include media-tablet {
        justify-content: flex-start;
        padding: 0; } } }

  &__title {
    @include fs18;
    @include reset;

    margin-top: 2.2222em;

    font-weight: 800;

    @include media-tablet {
      margin-top: 0; }

    @include media-tablet-h {
      @include h24; }

    @include media-desktop {
      @include h36; } }

  &__desc {
    display: flex;
    margin-top: 1em;
    flex-wrap: wrap;

    color: $grey-88;
    font-weight: 800;

    div {
      &:nth-child(n+2) {
        position: relative;

        margin-left: 0.875em;

        &::before {
          content: "";
          position: absolute;
          left: -9px;
          top: 50%;
          transform: translateY(-50%);

          width: 4px;
          height: 4px;

          border-radius: 50%;
          background-color: $grey-88; } } } }

  &__type {
    margin: 1em 0 2.5em;

    color: $blue;
    font-weight: 800;

    @include media-tablet {
      margin: 1em 0; } }

  &__change-link {
    @include fs11;

    margin-top: 1em;

    align-self: center;

    @include media-tablet {
      margin-top: 0; }

    a {
      color: $blue; } } }
