.stands {
  padding-top: 5em;
  background-color: $grey-f0;

  @include media-tablet {
    padding-top: 5em; }

  .cont {
    width: 100%;

    @include media-desktop-1600 {
      width: 100rem; } }

  &__title {
    font-weight: 800;
    color: $color-black; }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.25em; }

  &__filter {
    padding-bottom: 5em;

    &-list {
      @extend .schedule__filter-list;
      gap: 0.75rem; }

    &-item {}

    &-btn {
      @include fs16;
      min-width: auto;
      height: auto;
      padding: 0.46875rem 0.75rem;
      border-radius: 2.875rem;
      border: 1px solid $grey-e5;
      color: $color-black;
      background: $grey-e5;

      @include media-desktop {
        @include fs18;
        padding: 0.5625em 0.75rem; }

      &:hover {
        border-color: $blue; }

      &--active {
        border-color: $blue;
        color: $blue;
        background: $white; } }

    &-group {
      margin-top: 2.5em;

      &--direction {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }

    &-select {
      position: relative;
      width: 100%;
      border-radius: 2rem;
      background-color: $white;

      @include media-tablet {
        width: auto; }

      .netw-filter__country-reset {
        padding: 0 1.1rem; }

      &::after {
        content: "";
        position: absolute;
        right: 2em;
        top: 50%;
        width: 11px;
        height: 18px;
        transform: translateY(-50%) rotate(90deg);
        background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-slider-arrow.svg");
        background-repeat: no-repeat;
        z-index: 53; }

      input {
        @include p18;
        position: relative;
        display: block;
        width: 100%;
        padding: 0.666em 1.111em;
        border: none;
        border-radius: 2rem;
        background-color: transparent;
        appearance: none;
        z-index: 5;

        @include media-tablet {
          width: 22.5em;
          display: inline; } } }

    &-show-text {
      @include reset;
      @include p18;
      margin-top: 1rem;
      color: $dark-grey;
      border: none;

      @include media-tablet {
        margin-left: 1.777em;
        margin-top: 0; }

      button {
        color: $grey-80;
        font-size: 1em;
        background: $grey-f0;
        border: none;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none; } } } }

  &__general {
    padding-top: 2.5em;
    padding-bottom: 5em;
    background-color: $color-black;

    @include media-tablet {
      padding-top: 5em; } }

  &__not-general {
    position: relative;
    padding-top: 2.5em;
    padding-bottom: 5em;
    z-index: 1;
    background-color: $grey-f0;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      z-index: -1;
      background: $color-yellow-new;
      width: 100%;
      left: 0;
      right: 0;
      height: 18em;
      border-bottom-left-radius: 51em 7em;
      border-bottom-right-radius: 22em 8em;

      @include media-tablet {
        height: 36em;
        border-bottom-left-radius: 45em 31em;
        border-bottom-right-radius: 37em 31em; }

      @include media-tablet-h {
        border-radius: 0;
        left: initial;
        right: 50%;
        background: url(@public/images/stands-left-bg.svg) no-repeat center;
        width: 57em;
        margin-right: -12em; }

      @include media-desktop {
        margin-right: -10em; }

      @include media-desktop-1440 {
        margin-right: -3em; } }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 50%;
      margin-right: -24em;
      background: url(@public/images/stands-right-bg.svg) no-repeat center;
      width: 40.375em;
      height: 19.6em;
      z-index: -2;
      display: none;

      @include media-tablet-h {
        display: block;
        margin-right: -42em; }

      @include media-desktop {
        margin-right: -38em; }

      @include media-desktop-1440 {
        margin-right: -24em; } }

    @include media-desktop {
      overflow: hidden; }

    .cont {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 2em;

      @include media-tablet {
        row-gap: 4em; } } }

  &__group {
    .cont {
      display: grid;
      display: flex;
      flex-direction: column;
      row-gap: 2.5em; }

    &--hidden {
      display: none; }

    &-title {
      @include h24;
      position: relative;
      font-weight: 800;
      color: $color-black;

      @include media-tablet {
        @include h36; }

      span {
        color: $color-yellow-new;
        text-transform: uppercase; } }

    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 1.25em;

      @include media-tablet {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        column-gap: 1.875em;
        grid-row-gap: 1.875em; }

      @include media-tablet-h {
        row-gap: 2em;
        grid-template-columns: repeat(6, 0.5fr); }

      @include media-desktop {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2em; } }

    &--general {
      .stands__group-title {
        color: $white; } }

    &--partners {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 2.5em;

      span {
        color: inherit; } }

    &--participants {
      display: flex;
      flex-direction: column;
      row-gap: 2.5em;

      span {
        color: inherit; } } }

  &__slider {
    &:nth-of-type(n + 2) {
      margin-top: 2.5em;

      @include media-tablet {
        margin-top: 2em; } } }

  .swiper-pagination {
    position: relative;
    bottom: 0 !important;
    margin-top: 1.5em;

    @include media-tablet {
      display: none; } }

  .swiper-container {
    width: 100%; }

  .swiper-pagination-bullet {
    width: 0.625em;
    height: 0.625em;
    background-color: $grey-89;
    opacity: 1;

    &-active {
      background-color: $color-black; } }

  &__group--general {
    .swiper-pagination-bullet {
      background-color: $white;

      &-active {
        background-color: $color-yellow-new; } } } }
