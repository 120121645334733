.articles {
  padding-top: 2.6875em;
  background-color: $grey-f0;
  position: relative;

  &__header {
    margin-bottom: 2.5em; }

  &__title {
    @include reset;
    @include h36;
    color: $color-black;

    @include media-tablet {
      @include h48;
      max-width: 32.1875rem; }

    @include media-tablet-h {
      max-width: initial; } }

  &__body {
    .cont {
      @include max-w(768px) {
        width: 100%; } }

    .swiper-container.swiper-container-horizontal {
      padding-bottom: 4.5625em;

      @include media-tablet {
        padding-bottom: 6.25em; }

      @include media-tablet-h {
        padding-bottom: 7em; }

      .swiper-pagination {
        bottom: 2.1625em;

        @include media-tablet {
          bottom: 3.2875em; }

        @include media-tablet-h {
          bottom: 4.6625em; } }

      .swiper-pagination-bullet {
        width: 0.625em;
        height: 0.625em;
        margin: 0 0.25em;
        background: #888899;
        opacity: 1;

        @include media-tablet {
          margin: 0 0.375em; } }

      .swiper-pagination-bullet-active {
        background-color: $color-black; } } }

  &__slider {
    &-wrapper {
      position: relative;
      width: 100%;
      min-width: 1px; }

    &-nav {
      position: absolute;
      top: 50%;
      z-index: 50;
      display: none;
      width: 2.9375em;
      height: 3.5em;
      background-color: $grey-80;
      border-radius: 2rem 0 0 2rem;
      background-image: url(@public/images/prizes/prizes-slider-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      @include media-tablet {
        display: block; }

      &:hover {
        background-color: $grey; }

      &--prev {
        left: 0;
        transform: translateY(-4.5em) rotate(180deg);

        @include media-tablet {
          transform: translateY(-5.875em) rotate(180deg); } }

      &--next {
        right: 0;
        transform: translateY(-4.5em);

        @include media-tablet {
          transform: translateY(-5.875em); } }

      &--disabled {
        display: none; } } }

  &__slide {
    display: grid;
    grid-template-rows: 12.5625em 1fr;
    background-color: $white;
    border-radius: 1.25em;
    overflow: hidden;

    @include media-tablet {
      grid-template-rows: none;
      grid-template-columns: 22.125em 1fr; }

    @include media-tablet-h {
      grid-template-columns: 14.5625em 1fr; }

    @include media-desktop {
      grid-template-columns: 17em 1fr; }

    @include media-desktop-1440 {
      grid-template-columns: 19.5em 1fr; }

    @include media-desktop-1600 {
      grid-template-columns: 22em 1fr; }

    &-bg {
      height: 12.5625em;

      @include media-tablet {
        height: 100%; }

      img {
        @include cover-img; } }

    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 1.5em;
      padding: 1.25em;

      @include media-tablet {
        padding: 1.5em;
        min-height: 17.1875em; }

      @include media-tablet-h {
        min-height: 22.5em; }

      @include media-desktop {
        min-height: 29.375em;
        padding: 2em; }

      @include media-desktop-1440 {
        padding: 2.5em; }

      @include media-desktop-1600 {
        min-height: 26.625em; } }

    &-title {
      @include reset;
      @include fs18;
      font-weight: 700;
      color: $dark-grey;

      @include media-desktop {
        @include h24; } }

    &-desc {
      @include p14;
      min-height: 6.875rem;
      color: $dark-grey;

      @include media-tablet {
        min-height: auto; }

      @include media-desktop {
        @include p18; } }

    &-btn {
      margin-top: auto;

      @include media-tablet {
        width: 8.3125rem;
        min-width: auto; } } } }
