.position {
  padding: 2.5em 0;
  background: $grey-f0;
  color: $color-black;

  @include media-tablet {
    padding: 2em 0; }

  &__main {
    position: relative;
    padding-bottom: 7.25em;
    border-radius: 1.5em;
    background: $white;
    overflow: hidden;

    @include media-tablet {
      padding-bottom: 6.75em; }

    @include media-tablet-h {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0; }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -8.125em;
      width: 36.125em;
      height: 3.0625em;
      background: url(@public/images/position/main-before-mobile.svg) no-repeat;
      z-index: 1;

      @include media-tablet {
        left: 25.125em;
        width: 46.375em;
        height: 19.4375em;
        background-image: url(@public/images/position/main-before-tablet.svg); }

      @include media-tablet-h {
        left: 29.25em;
        width: 31.75em;
        height: 15.1875em;
        background-image: url(@public/images/position/right-before-tablet-h.svg); }

      @include media-desktop {
        left: 30.375em;
        width: 39.0625em;
        height: 15.1875em;
        background-image: url(@public/images/position/right-before-desktop.svg); }

      @include media-desktop-1440 {
        left: 36.125em;
        width: 44em;
        height: 15.1875em;
        background-image: url(@public/images/position/right-before-desktop-1440.svg); } }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -3em;
      width: 38.3125em;
      height: 6.1875em;
      background: url(@public/images/position/main-after-mobile.svg) no-repeat;
      z-index: 2;

      @include media-tablet {
        left: -5em;
        width: 52.125em;
        height: 5.5em;
        background-image: url(@public/images/position/main-after-tablet.svg); }

      @include media-tablet-h {
        display: none; } } }

  &__left {
    padding: 1.875em 0.9375em 0;

    @include media-tablet {
      max-width: 31.625em;
      padding: 3.75em 1.75em 0; }

    @include media-tablet-h {
      padding: 2em 0 2em 3.125em; }

    @include media-desktop {
      width: 38.4375em;
      max-width: none;
      padding: 3.125em 0 3.125em 3.125em; }

    @include media-desktop-1440 {
      padding: 3.125em 0 3.125em 3.125em; }

    @include media-desktop-1600 {
      width: 44.375em; } }

  &__title {
    @include h36;
    max-width: 16.1875rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;

    @include media-tablet {
      margin-bottom: 1.875rem; }

    @include media-tablet-h {
      width: 18.4375rem;
      max-width: none; }

    @include media-desktop {
      @include h48;
      width: 21.625rem;
      margin-bottom: 2rem; }

    @include media-desktop-1600 {
      width: 100%; } }

  &__desc {
    @include media-desktop-1600 {
      width: 35.3125em; }

    p {
      @include p14;
      margin: 0 0 1rem;

      @include media-tablet {
        @include p18;
        margin-bottom: 1.25rem; }

      @include media-tablet-h {
        @include fs18; }

      @include media-desktop {
        @include fs24;
        margin-bottom: 1.5rem; }

      @include media-desktop-1600 {
        margin-bottom: 1.5rem; } }

    ul {
      @include p14;
      margin: 0;
      padding-left: 1.375rem;

      @include media-tablet {
        @include p16; }

      @include media-tablet-h {
        max-width: 34.25rem; }

      @include media-desktop {
        @include p18; }

      @include media-desktop-1440 {
        max-width: 34.875rem; }

      li:not(:last-child) {
        margin-bottom: 0.5rem;

        @include media-tablet {
          margin-bottom: 0.75rem; }

        @include media-desktop {
          margin-bottom: 0.75rem; } } } }

  &__right {
    position: relative;
    display: none;
    z-index: 10;

    @include media-tablet-h {
      flex: 0 0 25.3125em;
      display: block; }

    @include media-desktop {
      flex-basis: 30.9375em; }

    @include media-desktop-1440 {
      flex-basis: 37.1875em; }

    @include media-desktop-1600 {
      flex-basis: 40.875em; } }

  &__img {
    position: relative;
    display: block;
    z-index: 2;

    img {
      display: block; } } }
