.d-flex {
  display: flex; }

.flex-grow {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 0; }

.jc-between {
  justify-content: space-between; }

.jc-evenly {
  justify-content: space-evenly; }

.jc-center {
  justify-content: center; }

.jc-end {
  justify-content: flex-end; }

.jc-start {
  justify-content: flex-start; }

.ai-start {
  align-items: flex-start; }

.ai-center {
  align-items: center; }

.ai-evenly {
  align-items: space-evenly; }

.ai-end {
  align-items: flex-end; }

.flex-wrap {
  display: flex;
  flex-wrap: wrap; }

.flex-nowrap {
  flex-wrap: nowrap; }

.fd-column {
  display: flex;
  flex-direction: column; }

.fd-row {
  flex-direction: row; }

.aself-center {
  align-self: center; }

.jself-end {
  justify-self: end; }

.aself-end {
  align-self: center; }
