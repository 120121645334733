.stand-request {
  display: grid;
  row-gap: 2.5em;

  &__title {
    @include h24;

    @include media-tablet {
      @include h36; } }

  &__body {
    display: grid;
    row-gap: 0.75em;

    @include media-tablet-h {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2em;
      row-gap: 1.5em; } }

  &__row {
    display: grid;
    row-gap: 0.75em;

    &-title {
      font-weight: 800; }

    &-body {
      display: grid; }

    &--first {
      @include media-tablet-h {
        grid-column: 1; }

      .stand-request__row-body {
        row-gap: 1em;

        @include media-tablet {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1em; } } }

    &--second {
      @include media-tablet-h {
        grid-column: 2; }

      .stand-request__row-body {
        @include media-tablet {
          grid-template-columns: repeat(2, 1fr); } } }

    &--third {
      @include media-tablet-h {
        grid-column: 1 / span 2; } } }

  .react-datepicker__input-container {
    &::after {
      content: "";

      position: absolute;
      right: 0.875em;
      top: 50%;
      transform: translateY(-50%);

      width: 0.875em;
      height: 0.875em;

      background-image: url("../images/stand-request.svg");
      background-repeat: no-repeat;
      background-position: center; } }

  .react-datepicker__tab-loop {
    position: absolute; }

  .react-datepicker-wrapper {
    transition: none;
    &:first-of-type {
      input[type="text"] {
        border-top-left-radius: 0.2857em;
        border-top-right-radius: 0.2857em;
        border-bottom: none;

        @include media-tablet {
          border-bottom-left-radius: 0.2857em;
          border-top-right-radius: 0;
          border-bottom: 1px solid $grey-c0;
          border-right: none; } } }

    &:last-of-type {
      input[type="text"] {
        border-bottom-left-radius: 0.2857em;
        border-bottom-right-radius: 0.2857em;

        @include media-tablet {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0.2857em;
          border-top-right-radius: 0.2857em; } } }

    input[type="text"] {
      @include p14;

      width: 100%;
      padding: 0.5714em 2em 0.5714em 0.8571em;

      color: $grey-88;

      background-color: $grey-f5;
      border: 1px solid $grey-c0; } }

  .textarea {
    height: 5.4286em;
    margin-top: 0.8571em; } }
