.w-100 {
  width: 100%; }

.w-50 {
  width: 50%; }

.w-40 {
  width: 40%; }

.fw-300 {
  font-weight: 300; }

.h-100 {
  height: 100%; }

.h-auto {
  height: auto; }

.fs-sm-phone {
  @media (max-width: $phone-width) {
    font-size: 0.8em; } }

.hide-on-phone {
  @media (max-width: $phone-width) {
    display: none !important; } }

.show-on-phone {
  @media (min-width: $phone-width + 1px) {
    display: none !important; } }

.hide-on-desktop {
  @media (min-width: $tablet-width) {
    display: none !important; } }

.show-on-desktop {
  @media (max-width: $tablet-width) {
    display: none !important; } }

// hider
$max: 2000;
$step: 10;
@for $i from 0 through ceil($max/$step) {
  $value: ($i * $step);
  .hide-on-#{$value} {
    @media (max-width: #{$value}px) {
      display: none !important; } }
  .show-on-#{$value} {
    @media (min-width: calc(#{$value}px + 1px)) {
      display: none !important; } } }
