@for $i from 0 through 12 {
  .col-size-#{$i} {
    width: #{$i}em !important;
    max-width: #{$i}em !important;
    min-width: #{$i}em !important; } }

@for $i from 0 through 100 {
  .col-size-#{$i}x {
    width: #{$i}% !important;
    max-width: #{$i}% !important;
    min-width: #{$i}% !important; } }
