.page-videotest {
  z-index: 100000;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  margin-bottom: -100px;
  left: 0;
  .video {
    height: 100vh; }
  #jaas-container {
    height: 100vh; } }
