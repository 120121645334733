.stand-spec {
  display: grid;
  row-gap: 2rem;

  &__title {
    @include reset;
    @include fs18;

    font-weight: 800; }

  &__list {
    @include reset;

    display: flex;
    flex-wrap: wrap;
    row-gap: 0.75rem;
    column-gap: 0.75rem;

    list-style: none; }


  &__item {
    padding: 0.3125em 0.5625em;

    background-color: $grey-f5;
    border-radius: 2rem; } }
