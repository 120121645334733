.favorites-card {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  padding: 1.5rem;

  background-color: $white;
  border-radius: 1.25rem;

  @include media-tablet {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem 2.5rem; }

  @include media-desktop-1440 {
    column-gap: 5.25em;
    padding: 2.5rem; }

  &__partner {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.75rem;

    @include media-tablet {
      flex-direction: row;
      column-gap: 1rem;
      flex: 1;
      margin-bottom: 0; }

    &-logo {
      display: block;
      width: 5rem;
      height: 5rem;
      margin-bottom: 1em;

      @include media-tablet {
        flex: 0 0 auto;
        margin-bottom: 0; }

      img {
        @include cover-img;
        border-radius: 50%; } }

    &-name {
      @include fs16;

      display: block;
      margin-bottom: 0.5rem;

      font-weight: 800;

      @include media-tablet {
        @include fs18; }

      &:hover {
        color: $blue;
        text-decoration: underline; } }

    &-desc {
      @include reset;
      @include fs11; } }

  &__company {
    display: flex;
    align-items: center;
    column-gap: 0.75em;
    margin-bottom: 1.5rem;

    @include media-tablet {
      flex: 1;
      margin-bottom: 0; }

    &-title {
      @include reset;
      @include fs14;

      color: #000;
      font-weight: 800; }

    &-logo {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      flex-shrink: 0;
      width: 5.625rem;
      height: 3.75rem;
      padding: 0.46875rem;

      border-radius: 0.25rem;
      border: 1px solid $grey-e5;

      img {
        @include contain-img; } } }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    width: 100%;
    padding-top: 1.5rem;

    border-top: 1px solid $grey-e5;

    @include media-tablet {
      flex-direction: row; }

    @include media-desktop-1440 {
      flex-direction: column;
      justify-content: center;
      width: auto;
      min-height: 5.5rem;
      padding-top: 0;
      padding-left: 2rem;

      border-top: none;
      border-left: 1px solid #e5e5e5; } }

  &--stand,
  &--company {
    .favorites-card {
      &__company {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.5rem;

        @include media-tablet {
          flex-direction: row;
          align-items: center; } }

      &__company-title {
        @include fs16; } } } }
