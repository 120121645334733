.checkbox {
  &--profile {
    label {
      @include fs16;

      display: block;
      padding: 0.3125em 0.5em;

      border-radius: 2rem;
      border: 1px solid $grey-f5;
      background-color: $grey-f5;
      user-select: none;
      cursor: pointer; } }

  input:checked + label {
    border: 1px solid $blue; }

  &--profile-language {
    label {
      @include fs18;

      display: block;
      padding: 0.4444em 0.8889em;

      border-radius: 2rem;
      border: 1px solid $grey-f5;
      background-color: $grey-f5;
      user-select: none;
      cursor: pointer; }

    input:checked + label {
      border: 1px solid $blue; } } }
