.company-employees {
  display: grid;
  row-gap: 2.5em;

  &__tabs {
    &-nav {
      display: flex;

      &-item {
        @include fs14;

        padding: 0.5714em 1.4286em;

        color: $grey-88;

        border-top-left-radius: 0.5714em;
        border-top-right-radius: 0.5714em;
        background-color: $grey-ee;

        &--active {
          color: $dark-grey;
          font-weight: 800;

          background-color: $white; } } }

    &-body {
      border-top-left-radius: 0em; } }

  &__inbox {
    @include lreset;

    display: grid;
    row-gap: 2em;

    &-divider {
      @include reset;

      height: 0.0625em;
      margin-left: -2.5em;
      margin-right: -2.5em;

      border: none;
      background-color: $grey-e5; }

    &-item {
      display: grid;
      grid-template-columns: 1fr auto 10.9375em;
      column-gap: 2em;

      hr {
        @include reset;

        width: 0.0625em;
        height: 100%;
        background-color: $grey-e5;
        border: none; }
      &-body {
        display: grid;
        grid-template-columns: 18.625em 1fr;
        column-gap: 5.25em;
        align-items: center; }

      &-user {
        display: grid;
        grid-template-columns: 2.5em 1fr;
        column-gap: 0.75em;
        row-gap: 0.25em;
        grid-template-areas: "img name" "img desc"; }

      &-img {
        grid-area: img;
        width: 2.5em;
        height: 2.5em;

        border-radius: 50%;
        overflow: hidden;
        background-color: $grey-e5;

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center; } }


      &-name {
        @include fs18;

        grid-area: name; }

      &-desc {
        @include fs11;

        grid-area: desc;

        color: $grey-88; }

      &-contacts {
        display: flex;
        flex-direction: column;

        a {
          @include fs14;

          color: $dark-grey; } }

      &-buttons {
        display: grid;
        row-gap: 0.75em;

        .btn {
          padding: 0;

          &--grey {
            color: $dark-grey;

            background-color: $grey-e5; } } }

      &-status {
        font-weight: 400;

        border: 1px solid $red;
        cursor: default;

        img {
          margin-left: 0.5714em; } } }

    &--declined {
      .company-employees {
        &__inbox-item {
          &-body {
            opacity: 0.5; } } } } }

  &__approved {
    display: grid;
    row-gap: 2.5em;

    &-title {
      @include h24; }

    &-list {
      display: grid;
      row-gap: 2.5em;

      hr {
        @include reset;

        height: 0.0625em;

        background-color: $grey-e5;
        border: none; } }

    &-item {
      display: grid;
      row-gap: 1.5em;

      &-body {
        display: grid;
        grid-template-areas: "img name" "img position" "img role";
        grid-template-columns: 4em 1fr;
        column-gap: 0.75em;
        row-gap: 0.25em; }

      &-img {
        grid-area: img;
        width: 4em;
        height: 4em;

        border-radius: 50%;
        overflow: hidden;
        background-color: $grey-e5;

        img {
          object-fit: cover;
          object-position: center; } }


      &-name {
        @include fs18;

        grid-area: name;
        display: grid;
        column-gap: 0.4444em;
        align-items: center;
        grid-template-columns: auto 1fr; }

      &-position {
        @include fs14;

        grid-area: position;

        color: $grey-88; }

      &-role {
        @include fs11;

        grid-area: role;
        color: $dark-grey; }

      &-buttons {
        display: grid;
        grid-template-columns: repeat(3, max-content); }

      &-btn {
        @include fs11;

        display: flex;
        align-items: center;
        padding: 0.6364em 1em;
        margin-right: 0.7273em;

        border-radius: 2.0625em;

        img {
          margin-right: 0.7273em; }

        &--visibility {
          color: $grey-88;

          border: 1px solid #888888; }

        &--role {
          border: 1px solid $dark-grey; }

        &--delete {
          color: $blue;

          border: 1px solid $blue; } } } } }
