.stand-cats {
  display: grid;
  row-gap: 2.5em;

  &__title {
    @include h36; }

  &__list {
    display: grid;
    row-gap: 1.5em; }

  &__item {
    display: grid;
    row-gap: 1em;
    padding: 1.5em;

    background-color: $grey-f5;
    border-radius: 0.25em;

    @include media-tablet {
      grid-template-columns: 1fr auto;
      column-gap: 2em;
      align-items: center; }

    &-img {
      width: 100%; }

    &-text {}

    &-link {
      color: $grey-88;

      span {
        color: $red; } } } }
