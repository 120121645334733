.input-ac {
  position: relative;
  // border: 1px solid $grey-e5

  &--search {
    &::after {
      content: "";

      width: 18px;
      height: 18px;

      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);

      background-image: url("../images/search.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; } }

  &--company-status {
    .input-ac {
      &__item {
        a {
          justify-content: space-between;

          span {
            margin-left: 1em;

            color: $blue; } } } } }

  &__input {
    @extend .input[type="text"]; }

  &__dropdown {
    @include lreset;

    position: absolute;
    left: 0;
    right: 0;
    z-index: 101;

    display: grid;
    row-gap: 0.4375em;
    height: 0;
    overflow-y: scroll;
    max-height: 32em;

    background-color: $white;
    transition: none; }

  &--open {
    .input-ac {
      &__input {
        border-bottom: none !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: 1rem 1rem 0 0;
        outline: none; }

      &__dropdown {
        height: auto;
        padding: 0.4375em 0.75em;

        border: 1px solid $grey-e5;
        border-top: 0;
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;

        &::before {
          content: "";

          position: absolute;
          top: 0;
          left: 0.75em;
          right: 0.75em;

          height: 1px;
          background-color: $grey-e5; } }

      &__item {
        a {
          @include fs14;

          display: block;
          display: flex;
          align-items: center;
          padding: 0.7857em 0;

          color: $dark-grey; } } } } }
