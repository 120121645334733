@mixin lreset {
  margin: 0;
  padding: 0;
  list-style: none; }

@mixin reset {
  margin: 0;
  padding: 0; }

@mixin contain-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

@mixin cover-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }
