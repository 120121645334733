.footer {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  overflow: hidden;
  color: $white;
  background-color: $dark-grey;

  .cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-tablet-h {
      padding-left: 1.875em;
      padding-right: 1.875em; }

    @include media-desktop-1440 {
      flex-wrap: nowrap; }

    @include media-desktop-1600 {
      padding-left: 2.5em;
      padding-right: 2.5em; } }

  &__top {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 2em 2.5em;
    width: 100%;
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: 1px solid $grey-88;

    @include media-desktop {
      gap: 1.5em 2em;
      align-content: space-between;
      max-width: 16.125em;
      margin-right: 2em;
      margin-bottom: 0;
      padding-right: 2em;
      padding-bottom: 0;
      border-right: 1px solid $grey-88;
      border-bottom: none;

      &:after {
        display: none; } }

    @include media-desktop-1440 {
      max-width: 19em;
      margin-right: 5em;
      padding-right: 5em; } }

  &__logo {
    img {
      min-width: 10.125em; } }

  img {
    display: block; }

  &__text {
    @include fs16;
    width: 100%;
    color: $grey-88; }

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 2em;
    font-size: 1em;

    @include media-tablet-h {
      flex-wrap: nowrap; }

    @include media-desktop {
      justify-content: space-evenly; } }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1em;
    width: 100%;
    max-width: 21em;

    @include media-desktop {
      max-width: 12.5em; }

    @include media-desktop-1440 {
      max-width: 12.3125em; }

    &--long {
      @include media-desktop {
        max-width: 14em; }

      @include media-desktop-1440 {
        max-width: 16.25em; } }

    &-title {
      @include reset;
      @include fs14;
      color: $green-footer; }

    &-descr {
      display: flex;
      flex-direction: column;
      row-gap: 0.25em; }

    &-name {
      @include reset;
      @include fs16; }

    &-loyalty {
      white-space: nowrap; }

    a {
      @include fs14;
      color: $white;

      &:hover {
        color: $green-footer; } }

    &-contact-us {
      padding: 0.333rem 1.2rem;
      border: 1px solid $green-footer;
      border-radius: 0.4rem;
      background-color: transparent;

      &:hover {
        color: $white !important;
        background-color: $green-footer; } }

    &-loyalty {
      &-tg {
        display: inline-block;
        vertical-align: middle;
        width: 1.666em;
        height: 1.666em;
        margin-left: 0.416em;
        background: url(../images/tg_icon_ft.svg) center no-repeat; } } } }
