.cabinet-event {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 12.5rem;

  &--declined {
    .cabinet-event__column {
      opacity: 0.5;

      &:last-child {
        opacity: 1; } } }

  &__column {
    padding: 0 1rem;
    display: grid;
    row-gap: 0.5rem;
    align-items: start;
    grid-auto-rows: max-content;

    border-left: 1px solid $grey-e5;

    &:first-child {
      border-left: none;
      padding-left: 0; }

    &:last-child {
      margin-right: 0; } }

  &__small-text {
    @include fs11;

    color: $grey-88; }

  &__title {
    display: grid;
    row-gap: 0.25rem;

    &:hover {
      .cabinet-event__title-name {
        color: $blue;
        text-decoration: underline; } }

    &-name {
      @include fs18;

      font-weight: 800; }

    &-plus {
      line-height: 0.625rem;

      svg {
        width: 0.625rem;
        height: 0.625rem; } } }

  &__inviter {
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: repeat(2, max-content);
    column-gap: 0.75rem;
    row-gap: 0.25rem;

    &-img {
      grid-column: 1;
      grid-row: 1 / span 2;

      width: 2.5rem;
      height: 2.5rem;

      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center; } }

    &-name {
      @include fs14;

      font-weight: 800;

      &:hover {
        color: $blue;

        text-decoration: underline; } }

    &-company {
      @include fs11; } }

  &__desc {
    @include fs14; }

  &__chat-link {
    @include fs11;

    padding: 0.25rem 0.75rem;
    justify-self: start;
    margin-top: 0.5rem;

    border-radius: 2rem;
    background-color: $grey-f5;

    &:hover {
      color: $blue; } }

  &__status {
    color: $grey-c0;
    font-weight: 800;

    border: 1px solid $grey-88;
    pointer-events: none;

    svg {
      margin-left: 0.5rem; } } }
