.stand-questions {
  display: grid;
  row-gap: 2.5em;

  &__q-form {
    display: grid;
    row-gap: 2.5em;

    &-header {
      display: grid;
      row-gap: 1.5em; }

    &-title {
      @include h24;

      @include media-tablet {
        @include h36; } }

    &-desc {
      @include fs14;

      @include media-tablet {
        @include fs18; } }

    &-body {
      display: grid;
      row-gap: 1.5em; }

    &-disclaimer {
      @include fs14;

      font-weight: 800;

      @include media-tablet {
        @include fs16; } }

    textarea {
      height: 6.6875em; }

    .btn {
      @include media-tablet-h {
        justify-self: start;
        min-width: 14.7143em; } } }

  hr {
    height: 0.0625em;
    margin: 0;

    border: none;
    background-color: $dark-grey; }

  &__q-list {
    display: grid;
    row-gap: 2.5em;

    &-title {
      @extend .stand-questions__q-form-title; } }

  &__more-link {
    @include p14;

    padding: 0.5714em 0;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $dark-grey;

    border-radius: 0.2857em;
    background-color: $grey-f5;

    img {
      margin-left: 1em; } } }
