.btn {
  @include fs14;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 13.1429em;
  height: 2.5714em;
  padding-left: 1.7143em;
  padding-right: 1.7143em;
  font-family: $base-font;
  font-weight: 400;
  border-radius: 2.3571em;
  border: none;
  background-color: transparent;
  transition: all 0.2s linear;

  &--medium {
    @include fs16;
    min-width: 14.5em;
    height: 2.8125em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 2.0625em; }

  &--large {
    @include fs18;
    height: 3.0556em;
    padding-left: 3.1111em;
    padding-right: 3.1111em;
    border-radius: 2.5em; }

  &--small {
    @include h24;
    height: 2.8em;
    padding-left: 1.8em;
    padding-right: 1.8em;
    border-radius: 2.5em; }

  &--red {
    font-weight: 800;
    color: $white;
    background-color: $red;

    &:hover {
      color: $grey-88;
      background-color: $grey-f5; } }

  &--yellow {
    font-weight: 800;
    color: #000;
    background-color: $color-yellow-new;

    &:hover {
      color: $grey-88;
      background-color: $grey-f5; } }

  &--blue {
    font-weight: 800;
    color: $color-black;
    background-color: $blue;

    &:hover {
      color: $grey-88;
      background-color: $grey-f5; } }

  &--blue-white {
    font-weight: bold;
    color: $white;
    background-color: $blue;

    &:hover {
      color: $grey-88;
      background-color: $grey-f5; } }

  &--grey {
    color: $dark-grey;
    background-color: $grey-f5;

    &:hover {
      background-color: $white;
      border: 1px solid $grey-c0; } }

  &--dark-grey {
    color: $dark-grey;
    background-color: $grey-e5;

    &:hover {
      color: $blue;
      background-color: $white;
      border: 1px solid $blue; } }

  &--white {
    color: $grey-88;
    background-color: $white;

    &:hover {
      color: $red; } }

  &--transparent {
    color: $grey-88;
    background: transparent;

    &:hover {
      color: $blue; } }

  &--white-red {
    font-weight: 800;
    color: $red;
    background-color: $white;
    border: 1px solid $red;

    &:hover {
      color: $white;
      background-color: $red; } }

  &--white-blue {
    font-weight: 800;
    color: $blue;
    background-color: $white;
    border: 1px solid $blue;

    &:hover {
      color: $white;
      background-color: $blue; } }

  &--white-grey {
    font-weight: 800;
    color: $grey-88;
    background-color: $white;
    border: 1px solid $grey-88;

    &:hover {
      background-color: $grey-e5;
      border-color: $grey-e5; } }

  &--white-grey-2 {
    font-weight: 800;
    color: $color-black;
    background-color: $white;
    border: 1px solid $color-black;

    &:hover {
      background-color: $grey-e5;
      border-color: $grey-e5; } }

  &--white-black {
    font-weight: 800;
    color: $color-black;
    background-color: $white;
    border: 1px solid $color-black;

    &:hover {
      background-color: $grey-e5;
      border: none; } }

  &--stand {
    color: $color-black;
    background-color: $grey-e5;

    &:hover {
      font-weight: 800;
      color: $color-black;
      background-color: $color-yellow-new; } }

  &--reg {
    font-weight: 800;
    color: $white;
    text-transform: uppercase;
    background-color: $red;
    box-shadow: 0.2222em 0.2222em 0.6667em rgba(0, 0, 0, 0.15);

    &:hover {
      color: $grey-88;
      background-color: $grey-f5; } }

  &--schedule {
    @include fs18;
    height: 2.8125rem;
    display: grid;
    grid-template-columns: 1fr 2.8125rem;
    align-items: stretch;
    min-width: 0;
    padding: 0;
    overflow: hidden;
    font-weight: 800;
    color: $color-black;
    background-color: transparent;
    border: 1px solid $blue;

    .btn {
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1em; }

      &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue;
        border-left: 1px solid $blue;

        svg,
        path {
          fill: $white; } } }

    &:hover {
      color: $white;
      background-color: $blue;

      .btn {
        &__status {
          border-left: 1px solid $white;

          svg,
          path {
            fill: $white; } } } }

    &-subscribed {
      color: $grey-c0;
      background: transparent;
      border: 1px solid $grey-c0;

      .btn {
        &__title {
          &:nth-child(1) {}

          &:nth-child(2) {
            display: none; } }

        &__status {
          background: transparent;
          border-left: 1px solid $grey-c0;

          svg {
            &:first-child {
              path {
                fill: none;
                stroke: $grey-e5; } }

            &:last-child {
              display: none; } } } }

      &:hover {
        background-color: $grey-f5;

        .btn {
          &__title {
            &:nth-child(1) {
              display: none; }

            &:nth-child(2) {
              display: flex;
              color: $grey-c0; } }

          &__status {
            border-left: 1px solid $grey-c0;

            svg {
              &:first-child {
                display: none; }

              &:last-child {
                display: block;
                transform: rotate(45deg);

                path {
                  fill: $blue; } } } } } } }

    &-watch {
      color: $dark-grey; } }

  &--table-schedule {
    @include fs11;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
    align-items: center;
    width: 7.1875rem;
    padding: 0;

    &:hover {
      .btn {
        &__status {
          background-color: $blue;

          svg,
          path {
            fill: $white; } } } }

    .btn {
      &__title {
        color: $grey-88;
        text-align: right; }

      &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.125rem;
        height: 2.125rem;
        border-radius: 50%;
        border: 1px solid $blue;

        svg {
          width: 0.625rem;
          height: 0.625rem; }

        svg,
        path {
          fill: $blue; } } }

    &-subscribed {
      .btn {
        &__title {
          &:nth-child(1) {}

          &:nth-child(2) {
            display: none; } }

        &__status {
          border: 1px solid $grey-c0;

          svg {
            &:first-child {
              path {
                fill: none;
                stroke: $grey-88; } }

            &:last-child {
              display: none; } } } }

      &:hover {
        .btn {
          &__title {
            &:nth-child(1) {
              display: none; }

            &:nth-child(2) {
              display: block; } }

          &__status {
            background-color: $white;

            svg {
              &:first-child {
                display: none; }

              &:last-child {
                display: block;
                transform: rotate(45deg);

                path {
                  fill: $grey-88; } } } } } } } }

  &--yellow-small {
    @include fs14;
    height: 2.6em;
    padding-left: 1.7143em;
    padding-right: 1.7143em;
    font-weight: bold;
    color: $color-black;
    background: $color-yellow-new;

    &:hover {
      background-color: $yellow-calendar;
 } }      // ! Ниже - не переписанные под стандарт кнопки

  &--grey-1 {
    font-weight: 400;
    color: $grey-88;
    background-color: $grey-e5; }

  &_icon {
    width: 1em;
    height: 1em;
    margin-left: 1em;
    object-position: center;
    object-fit: scale-down; }

  &--transparent-red {
    font-weight: 400;
    color: $red;
    background: none;
    border: $red 1px solid; }

  &--transparent-blue {
    font-weight: 400;
    color: $blue;
    background: none;
    border: $blue 1px solid; }

  &--transparent-c0 {
    color: $grey-c0;
    background: none;
    border: $grey-c0 1px solid; }

  &--new-field {
    position: relative;
    height: 2.7143em;
    font-weight: 400;
    color: $grey-88;
    background-color: $grey-f5;
    border: 1px solid $grey-e5;

    span {
      position: absolute;
      transform: translateY(-50%);
      width: 1em;
      height: 1em;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: $grey-88;
        border-radius: 20%; }

      &::before {
        left: 50%;
        width: 2px;
        height: 100%;
        transform: translateX(-50%); }

      &::after {
        width: 100%;
        height: 2px;
        transform: translateY(-50%); } } }

  &--skip {
    font-weight: 400;
    color: $grey-88; }

  &:disabled {
    color: $grey-c0 !important;
    background: $grey-e5 !important;
    cursor: auto; }

  &.disabled {
    background: $grey-e5 !important;
    color: $grey-c0 !important;
    cursor: auto; }

  &--filter-reset {
    text-decoration: underline;
    color: $blue;

    &-disabled {
      pointer-events: none; } }

  &--cutaway {
    color: $dark-grey;
    background-color: transparent;
    border-color: $grey-88;

    &-enabled {}
    &-disabled {
      color: $grey-c0;
      border-color: $grey-e5;
      pointer-events: none; }

    &-rejected {
      color: $grey-88;
      border-color: $grey-e5;
      pointer-events: none; } }

  &--user-cutaway {
    &-disabled,
    &-rejected {
      column-gap: 0.5rem;
      color: $grey-c0;
      pointer-events: none;

      &:hover {
        color: $grey-c0; } }

    &-rejected {
      &::after {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        background: url(@public/images/btn-cutaway-rejected.svg) no-repeat; } }

    &-enabled {
      column-gap: 0.5rem;
      color: $color-black;

      &::after {
        content: "";
        width: 1.125rem;
        height: 1.125rem;
        background: url(@public/images/btn-cutaway-enabled.svg) no-repeat; }

      &:hover {
        &::after {
          background-image: url(@public/images/btn-cutaway-enabled-hover.svg); } } } }

  &--favorites {
    column-gap: 0.5rem;
    color: $color-black;

    &::after {
      content: "";
      width: 1.125rem;
      height: 1.125rem;
      background: url(@public/images/btn-favorites.svg) no-repeat; }

    &:hover {
      color: $blue;

      &::after {
        background-image: url(@public/images/btn-favorites-hover.svg); } }

    &-added {
      color: $grey-c0;

      &::after {
        display: none; } } }

  &--favorites {
    &-inactive,
    &-active {
      width: 2rem;
      min-width: auto;
      height: 2rem;
      padding: 0;

      &::before {
        content: "";
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat; } }

    &-inactive {
      &::before {
        background-image: url(@public/images/btn-favorites-inactive.svg); }

      &:hover {
        &::before {
          background-image: url(@public/images/btn-favorites-active.svg); } } }

    &-active {
      &::before {
        background-image: url(@public/images/btn-favorites-active.svg); } } }

  &--blue-underline {
    justify-content: flex-start;
    min-width: auto;
    padding: 0;
    text-decoration: underline;
    color: $blue; } }
